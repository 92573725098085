import { Component, OnInit } from "@angular/core";
import { TrazaInternaService } from "src/app/_utils/services/Service-Entidades/TrazaInternaService";
import { TrazaInternaMdl } from "src/app/_modelos/Traza_interna";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { TurnosCRVisorComponent } from "../TurnosCerradosRecibidos-Visor/TurnosCR-visor.component";
import { MatDialog } from "@angular/material/dialog";
import { DinamicoService } from "src/app/_utils/services/dinamico.service";
import { yearsData } from "src/app/_utils/interface/interfaz";

@Component({
  selector: "app-turnos-list-cerradoRecibidos",
  templateUrl: "./Turnolistado-cerradorecibidos.component.html",
  styleUrls: ["./Turnolistado-cerradorecibidos.component.scss"],
})
export class TurnosListCerradoRecibidoComponent implements OnInit {
  turno: TrazaInternaMdl = new TrazaInternaMdl();

  data: any[] = [];
  turnos: TrazaInternaMdl[] = [];
  p: number = 1;
  Ancho = "100%";
  mensaje: boolean;
  instruccion: boolean;
  mens = "";
  ins = "";
  loading = false;

  cols: any[] = [
    //{ field: "trazaInternaId", header: "Turno" },
    { field: "Acciones", header: "Acciones", width: "10%" },
    { field: "estatus", header: "Estatus del Turno" },
    { field: "OrigenNombre", header: "Área que Turna" },
    { field: "DestinoNombre", header: "Área Destino" },
    { field: "numeroDocumentoOriginal", header: "No. oficio original" },
    { field: "documentoAsunto", header: "Asunto Oficio" },
    { field: "cadenaFolio", header: "Folio Interno" },
    { field: "fechaTurno", header: "Fecha del Turno" },
    { field: "agrupador", header: "Volante de Referencia" },
  ];

  isSave: boolean = true;

  /* array list that containt the years*/
  /* array list that containt the years*/
  selectedYear:any;
  YearList:yearsData[] = [];
  //YearList = [
    //{label:"2022", value:"2022"},
    //{label:"2021", value:"2021"},
  //];

  constructor(
    private TIservice: TrazaInternaService,
    private router: Router,
    public dialog: MatDialog,
    private dinamicoService: DinamicoService,
  ) { }

  ngOnInit() { 
    /**the paint the list when load*/
    this.YearList = this.dinamicoService.years();
    let year = this.YearList.map( (item) => item.value);//recorre the array list year
    let actualyear = {year:year[0]}//json that have the first array list
    this.find( 50, 1, actualyear)//call function to paint list of data
  }

  find(registros: number, pagina: number, actualyear: any) {
    this.loading = true;
    debugger;
    let numeroFinal = registros * pagina;
    let numeroInicio = numeroFinal - registros;

    debugger;

    //this.TIservice.TrazaInternaFindallCerradosRecibidosRange(    numeroInicio,    numeroFinal - 1  ).subscribe(  //paginador comentado
    let text = /^[0-9]+$/;//regex that validate only number 0 to 9
    if (actualyear.year != 0) {//valid if the year is different from 0
      if((actualyear.year != "") && (!text.test(actualyear.year))){//test for a match in a string
        this.loading = false;
        alert("Solo se admiten numeros");
        return false;
      }

      if (actualyear.year.length < 4 || actualyear.year.length > 4) {//check if value is less than 4 and check if value is greater than 4
        this.loading = false;
        alert("El anio no es correcto.");
        return false;
      }
    }

    if (actualyear.year == "") {//the validate if value is vacio
      this.loading = false;
      alert("El valor del anio esta vacio.");
      return false;
    }

    this.TIservice.TrazaInternaFindallCerradosRecibidosYear(actualyear).subscribe(
      data => {
        //console.log(data);
        this.turnos = data;
        this.turnos = this.turnos.filter((a) => a.ctrlActivo == true);
        this.data = this.turnos;

        this.Ancho = this.cols.length * 15 + "%";
        this.data.forEach((element) => {
          try {
            //Valida si organizacionorigen es nulo, toma el campo grupo organizacional origen
            if (element.organizacionIdOrigen == null) {
              element.OrigenNombre = element.grupoOrganizacionalIdOrigen.nombre;
            } else {
              // Toma el  organizacionOrigen
              element.OrigenNombre = element.organizacionIdOrigen.nombre;
            }
          } catch {
            element.OrigenNombre = "";
          }

          try {
            element.cadenaFolio = element.documentoFisicoId.cadenaFolio;
          } catch {
            element.cadenaFolio = "";
          }

          try {
            element.numeroDocumentoOriginal =
              element.documentoFisicoId.numeroDocumentoOriginal;
          } catch {
            element.numeroDocumentoOriginal = "";
          }

          try {
            element.documentoAsunto = element.documentoFisicoId.asunto;
          } catch {
            element.documentoAsunto = "";
          }

          try {
            element.fechaTurno = new Date(
              element.ctrlCreado
            ).toLocaleDateString();
          } catch {
            element.fechaTurno = "";
          }

          try {
            //Valida si organizacionorigen es nulo, toma el campo grupo organizacional origen
            if (element.organizacionIdDestino == null) {
              element.DestinoNombre =
                element.grupoOrganizacionalIdDestino.nombre;
            } else {
              // Toma el  organizacionOrigen
              element.DestinoNombre = element.organizacionIdDestino.nombre;
            }
          } catch {
            element.DestinoNombre = "";
          }

          try {
            element.estatus = element.estatusTurnoId.nombre;
          } catch {
            element.estatus = "";
          }

          try {
            element.agrupador = element.agrupador + "/" + "2021";
          } catch {
            element.agrupador = "";
          }
        });
        this.loading = false;
      },
      error => {
        console.log(error);
      }

    );
      
  }

  borrarTurno(tur: TrazaInternaMdl, i: number) {
    Swal.fire({
      title: "Eliminar",
      text: `Eliminará  ${tur.trazaInternaId}`,
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
    }).then((resp) => {
      if (resp.value) {
        // this.dependencias.splice(i, 1)
        this.turnos = this.turnos.filter(
          (a) => a.trazaInternaId !== tur.trazaInternaId
        );
        this.data = this.turnos.filter(
          (a) => a.trazaInternaId !== tur.trazaInternaId
        );
        tur.ctrlActivo = false;
        console.log(tur);
        this.TIservice.TrazaInternaRemove(tur.trazaInternaId).subscribe();
      }
    });
  }

  visorDocumentos(data) {
    if (data.documentoId != null) {
      this.router.navigate([
        "/turnos/visorCR/documento/",
        data.documentoId.documentoId,
      ]);
    } else {
      //this.router.navigate(['/turnos/visorCR/documentoFisico/', data.documentoFisicoId.documentoFisicoId]);
      const dialogRef = this.dialog.open(TurnosCRVisorComponent, {
        width: "50%",
        height: "90%",
        data: { id: data.documentoFisicoId.documentoFisicoId },
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }
  }

  DocRelacionado(data) {
    debugger;
    if (data.documentoId == null) {
      this.router.navigate([
        "/documentorelacionado/formulariofisico/" +
        data.documentoFisicoId.documentoFisicoId +
        "/new/bucketEntrada",
      ]);
    } else {
      this.router.navigate([
        "/documentorelacionado/formulario/",
        data.documentoId.documentoId + "/new/bucketEntrada",
      ]);
    }
  }

  Mensaje(turnos) {
    debugger;
    this.mensaje = true;
    this.TIservice.TrazaInternaFind(Number(turnos.trazaInternaId)).subscribe(
      (resp: TrazaInternaMdl) => {
        this.mens = resp.mensaje;
      }
    );
  }

  Instrucciones(turnos) {
    debugger;
    this.instruccion = true;
    this.TIservice.TrazaInternaFind(Number(turnos.trazaInternaId)).subscribe(
      (resp: TrazaInternaMdl) => {
        this.ins = resp.instrucciones;
      }
    );
  }

  CerrarTurno(turno): void {
    const id = turno.trazaInternaId;

    if (id !== "new") {
      this.isSave = false;

      debugger;
      this.TIservice.TrazaInternaFind(Number(id)).subscribe(
        (resp: TrazaInternaMdl) => {
          resp.estatusTurnoId = 3;

          this.ngOnInit();
          let peticion: Observable<any>;
          Swal.fire({
            title: "Espere",
            text: "Guardando información",
            icon: "info",
            allowOutsideClick: false,
          });

          debugger;
          peticion = this.TIservice.AutorizarEstatus(resp.trazaInternaId, resp);

          peticion.subscribe(
            (resp) => {
              this.ngOnInit();

              Swal.fire({
                title: id,
                text: "Realizado correctamente",
                icon: "success",
              });
            },

            (error) => {
              if (error) {
              }

              Swal.fire({
                title: id,
                text: "error",
                icon: "error",
              });
            }
          );
        }
      );
    }
  }

  /**Change the list year*/
  changeYear = (event) => {
    let yearChange = {year:event.value};
    this.data.length = 0;//the reset the array
    this.turnos.length = 0//the reset the array
    this.find( 50, 1, yearChange);//the call function for paint the data when change select
    this.selectedYear = event.value;
  }
}
