import { Component, OnInit } from '@angular/core';
import { DocumentoFisicoService } from 'src/app/_utils/services/Service-Entidades/documentoFisico.service';
import { DocumentoAreaVisorComponent } from './documento-area-visor/documento-area-visor.component';
import { MatDialog } from '@angular/material/dialog';
import { DinamicoService } from 'src/app/_utils/services/dinamico.service';
import { yearsData } from 'src/app/_utils/interface/interfaz';
import { VisorAdjuntosComponent } from '../../turnos/visor-adjuntos/visor-adjuntos.component';
import { AcuseDialogComponent } from '../acuse-dialog/acuse-dialog.component';

@Component({
  selector: 'app-documento-listado-area',
  templateUrl: './documento-listado-area.component.html',
  styleUrls: ['./documento-listado-area.component.scss']
})
export class DocumentoListadoAreaComponent implements OnInit {
  cols: any[] = [];
  dato: any = [];
  ListDocumentArea: any = [];
  ListData: any = [];
  selectedYear:any;

  public loading:boolean = false;
  public showTable:boolean = false;
  private year:string = "";
  private grupoOrganizacional:string = "";
  private organizacion:string = "";

  YearList:yearsData[] = [];
  
  constructor(
    private docfiscService: DocumentoFisicoService,
    public dialog: MatDialog,
    private dinamico:DinamicoService,
  ) { }

  ngOnInit(): void {
    this.docfiscService.GetDocumentoArea().subscribe({
      next: (docArea) => {
        this.ListDocumentArea = docArea;
      },
      error: (err) => {console.log(err);
      },
    })
    /**************************************** */
    this.cols = [
      {field: "Acciones", header: "Acciones"},
      { field: "numeroDocumentoOriginal", header: "Oficio Original" },
      { field: "asunto", header: "Asunto" },
      { field: "puesto", header: "Puesto Remitente/Destinatario" },
      { field: "cadenaFolio", header: "Folio Interno" },
      { field: "ctrlCreado", header: "Fecha Creación" },
      { field: "entradaSalida", header: "Tipo Correspondencia" },
      { field: "tipoDocumento", header: "Tipo Documento" },
      { field: "prioridad", header: "Prioridad" },
    ];
  }


  getListDocumento = (event:any) => {
    this.loading = true;
    let year = new Date().getFullYear();
    this.grupoOrganizacional = event.value.grupoOrganizacionalDestinatarioId.toString();
    this.organizacion = event.value.organizacionDestinatarioId.toString();
    this.year = year.toString();
    this.findAll(this.grupoOrganizacional, this.organizacion, this.year);
  }

  findAll = (grupoOrganizacional:string,organizacion:string, year:string) => {
    let data = {
      grupoorganizacional_id:grupoOrganizacional,
      organizacion_id: organizacion,
      year: year,
    }
    this.docfiscService.documentoDTOFindAllArea(data).subscribe({
      next: (allArea) => {
        this.ListData = [];
        this.dato = [];
        this.YearList = this.dinamico.years();
        this.selectedYear = year; //selected item of value
        this.ListData = allArea;
        this.dato = allArea;
        this.dato.forEach((element:any) => {
          try {
            element.puesto = element.remitenteDestinatarioId.puesto;
          } catch (error) {
            element.puesto = "";
          }
          try {
            element.prioridad = element.prioridadDTOId.nombre; 
          } catch (error) {
            element.prioridad = "";
          }
          try {
            element.tipoDocumento = element.tipoDocumentoDTOId.nombre;
          } catch (error) {
            element.tipoDocumento = "";
          }
        });
        this.loading = false;
        this.showTable = true;
      },
      error: (err) => {
        console.log(err)
        this.loading = false;
        this.showTable = false
      }
    })

  }

  changeYear = (event:any) => {
    this.loading = true;
    this.showTable = false;
    let year = event.value;
    this.findAll(this.grupoOrganizacional, this.organizacion, year);
    this.selectedYear = event.value; //selected item of value
  }

  executeModal = (nomModal:any,paraetros:any) => { //function that execute the modal for show result
    const dialogRef = this.dialog.open(nomModal, {
      width: "50%",
      height: "90%",
      data: paraetros,
    }); 
  }

  visorDocumentArea = (data:any) => {
    let parametros = { id: data.documentoFisicoId};
    this.executeModal(DocumentoAreaVisorComponent,parametros);
  };

  visorAdjuntosArea = (data:any) => { 
    let parametros = { id: data.documentoFisicoId, tipoDoc: null};
    this.executeModal(VisorAdjuntosComponent,parametros);
  };

  visorAcusesArea = (data:any) => {
    let parametros = { id: data.documentoFisicoId, tipoDoc: "documentoFisico", modal: this.dialog};
    this.executeModal(AcuseDialogComponent,parametros);
  };

}
