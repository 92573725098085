<section class="content-header header-siadci">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <h1>Búsqueda de observaciones</h1>
            </div>
            <div class="col-sm-6">
                <h3>Muestra los resultados en documentos cerrados</h3>
            </div>
        </div>
    </div>
</section>
<section class="content content-sidci">
    <div class="container-fluid">
        <div class="card-siadci py-4">
            <div style="width: 95%; overflow: auto;margin-left: auto;margin-right: auto;">
                <div class="table-siadci" [style.Minwidth]="Ancho" style="width: 1500px;">
                    <p-table #dt class="ng2-smart-table" [columns]="colsDoc" [value]="dataDoc" [paginator]="true"
                        [rows]="10">
                        <ng-template pTemplate="caption" style="display: flex;">
                            <div class="search-wrapper-siadci d-flex">
                                <button pButton type="button" class="btn-mini-siadci" tittle="Acuse" icon="pi pi-search"
                                    (click)="busquedaDocumentoCerrado([buscarPalabra.value])" style="width: 50px;"></button>

                                <input #buscarPalabra type="text" pInputText placeholder="Buscar palabra" class="search-input"
                                    style="max-width: 500px;  margin-right: 10px;"     />
                                <i class="fa fa-edit"></i>
                            </div>
                                <!--<div class="text-left" style="">
                                    <h3> <span class="bg-danger rounded">No se ha ingresado ninguna palabra</span></h3>
                                </div>-->
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" >
                                    <div>
                                        {{col.header | titlecase}}
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th *ngFor="let col of columns">
                                    <input pInputText type="text" 
                                    (input)="dt.filter($event.target.value, col.field, 'contains')" style="width: 100%;">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <mat-spinner *ngIf="this.loadingdoc"></mat-spinner>
                            <tr [pSelectableRow]="rowData">
                                <td *ngFor="let col of columns">
                                    <div style="display: flex; white-space: normal; overflow: hidden">
                                        {{ rowData[col.field] }}
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</section>