<section class="content-header header-siadci">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <h1>Listado de Documentos por Área</h1>
      </div>
      <div class="col-sm-12">
        <h3>Muestra los registros de Documentos por Área</h3>
      </div>
    </div>
  </div>
</section>

<section class="content content-sidci">
  <div class="container-fluid">
    <div class="siadci-content-wrapper">
      <form>
        <div class="box-body">
          <div class="row mx-4 py-3">
            <div class="section-siadci col-12">
              <mat-form-field appearance="outline" style="width: 100%">
                <input
                  matInput
                  style="display: none"
                  required
                />
                <p-dropdown
                [options]="ListDocumentArea"
                optionLabel="organizacionDestinatario"
                (onChange)="getListDocumento($event)"
                filter="true"
                placeholder="Seleccionar área para ver los documentos"
                >
                </p-dropdown>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<!-- Main content -->
<section class="content content-sidci">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card-siadci py-2">
      <!-- <div class="card-body"> -->
      <div class="divContentTable">
        <div class="table-siadci">
          <mat-spinner *ngIf="this.loading"></mat-spinner>
          <p-table #dt class="ng2-smart-table"
            [resizableColumns]="true" styleClass="p-datatable-gridlines" [autoLayout]="true"
            [columns]="cols" [value]="ListData" [paginator]="true" [rows]="10" *ngIf="this.showTable" [style]="{width:'1500px'}">
            <ng-template pTemplate="caption">
              <div class="filtros">
                <div class="filtro1">
                  <div>
                    <p-dropdown [options]="YearList" [(ngModel)]="selectedYear" (onChange)="changeYear($event)" id="select-data-anio">
                    </p-dropdown>
                  </div>
                </div>
                <div class="filtro2">
                  <div class="search-wrapper-siadci">
                    <input type="text" pInputText placeholder="Filtro global" class="search-input"
                      (input)="dt.filterGlobal($event.target.value, 'contains')" />
                    <i class="fa fa-search"></i>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of cols">
                  <div class="tableHeader">
                    {{col.header}}
                  </div>
                </th>
            </tr>
            <tr>
              <th *ngFor="let col of cols" >
                <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')" style="width: 100%" />
              </th>
            </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-area>
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of cols">
                  <div class="tableBody " >
                    <ng-container class="" *ngIf="col.field == 'Acciones'">
                    <div class="" >
                      <button class="btn-mini-siadci" (click)="visorDocumentArea(rowData);" title="Visor del documento"><mat-icon >visibility</mat-icon></button>
                    </div>
                    <div>
                      <button class="btn-mini-siadci" (click)="visorAdjuntosArea(rowData);" title="Visor de documentos adjuntos"><mat-icon >preview</mat-icon></button>
                    </div>
                    <div>
                      <button class="btn-mini-siadci" (click)="visorAcusesArea(rowData);" title="Acuse de documentos"><mat-icon >check_small</mat-icon></button>
                    </div>
                    </ng-container>
                    {{area[col.field]}}
                  </div>
                </td>
            </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</section>