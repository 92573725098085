<section class="content-header header-siadci">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <h1>Listado Videos</h1>
        </div>
        <div class="col-sm-6">
            <h3>Muestra los videos de operación del sistema</h3>
          </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  
  <!-- Main content -->
  <section class="content content-sidci">
    <!-- Default box -->
    <div class="container-fluid">
      <!-- <div class="siadci-content-wrapper pt-3"> -->
  
  
        <div class="siadci-content-wrapper">
          <!-- <div class="container-fluid"> -->
           
  
            <div style="height: 100px" >
                <br>
                <ol>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/Home.mp4" target="_blank" >Home</a></h3></li>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/1_Captura_Documento_de_Entrada.mp4" target="_blank">Creación de Documento de Entrada</a></h3></li>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/2_Turnar_Documento_de_Entrada.mp4" target="_blank">Turnar Documento de Entrada</a></h3></li>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/3_Responder_Turno.mp4" target="_blank">Responder un Turno</a></h3></li>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/4_Responder_Oficio.mp4" target="_blank">Creación de Documento Respuesta</a></h3></li>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/5_Adjuntar_Acuse.mp4" target="_blank">Adjuntar un Acuse a un Documento</a></h3></li>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/6_Cierre_de_Documento_de_Entrada.mp4" target="_blank">Cerrar documento de Entrada</a></h3></li>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/7_Cierre_de_Conocimiento.mp4" target="_blank">Cerrar documento por Conocimiento</a></h3></li>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/8_Administración_de_Asistentes.mp4" target="_blank">Administración de Asistentes de Funcionarios</a></h3></li>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/9_Administración_Contactos.mp4" target="_blank">Administración de Contactos</a></h3></li>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/10_Administración_Grupo_de_Envío.mp4" target="_blank">Administración de Grupos de Envío</a></h3></li>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/11_Consultas.mp4" target="_blank">Consulta Específica de Documento</a></h3></li>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/12_Reportes.mp4" target="_blank">Generación de Reporte</a></h3></li>
                    <li><h3> <a href="http://siadci.veracruz.gob.mx/recursos/videos/13_Cambio_contraseña.mp4" target="_blank">Cambio de contraseña</a></h3></li>

                </ol>
             </div>
  
    
  
            <!-- /.row -->
          <!-- </div> -->
        </div>
        <!-- /.siadci-content-wrapper -->
      <!-- </div> -->
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->
  