<div class="row">

  <ng-container *ngIf="ListaAnexo64!=null">
    <div *ngFor='let url of ListaAnexo64 ' class="col-3" [ngSwitch]="url.anexo.mime">

      <ng-container *ngIf="url.anexo.ctrlActivo==true ">
        <div *ngSwitchCase="'image/png' || 'image/jpg' ">
          <img [src]="url.base64" height="100"> <br>

          <div class="row">
            <div class="col">
              {{url.anexo.nombreArchivoOriginal}}
            </div>

          </div>
        </div>

        <div *ngSwitchCase=" 'image/jpeg' ">
          <img [src]="url.base64" height="100"> <br>

          <div class="row">
            <div class="col">
              {{url.anexo.nombreArchivoOriginal}}
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'application/pdf'" style=" margin-top: 10px;">
          <i class="fas fa-file-pdf fa-6x"></i>

          <div class="row">
            <div class="col">
              {{url.anexo.nombreArchivoOriginal}}
            </div>
          </div>

        </div>

        <div *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  "
          style=" margin-top: 10px;">
          <i class="fas fa-file-excel-o fa-6x"></i>

          <div class="row">
            <div class="col">
              {{url.anexo.nombreArchivoOriginal}}
            </div>
          </div>

        </div>

        <div *ngSwitchDefault style=" margin-top: 10px;">
          <i class="fas fa-file fa-6x"></i>

          <div class="row">
            <div class="col">
              {{url.anexo.nombreArchivoOriginal}}
            </div>
          </div>

        </div>

        <div class="row">

          <button
            (click)="eliminarImagen(url)"
            class="btn-primary-siadci"
            >
            <i class="fa fa-trash red"></i>
          </button>
          <button (click)="downloadFile(url.base64, url.anexo.nombreArchivoOriginal)"
            class="btn-primary-siadci">
            <i class="fa fa-download icon-blue"></i>
          </button>

        </div>
      </ng-container>


    </div>

  </ng-container>

</div>
<div class="row">

  <ng-container *ngIf="ListaDocumentoFisico64!=null">
    <div *ngFor='let url of ListaDocumentoFisico64 ' class="col-3" [ngSwitch]="url.anexoDocumentoFisico.mime">

      <ng-container *ngIf="url.anexoDocumentoFisico.ctrlActivo==true ">
        <div *ngSwitchCase="'image/png' || 'image/jpg' ">
          <img [src]="url.base64" height="100"> <br>

          <div class="row">
            <div class="col">
              {{url.anexoDocumentoFisico.nombreArchivoOriginal}}
            </div>

          </div>
        </div>

        <div *ngSwitchCase=" 'image/jpeg' ">
          <img [src]="url.base64" height="100"> <br>

          <div class="row">
            <div class="col">
              {{url.anexoDocumentoFisico.nombreArchivoOriginal}}
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'application/pdf'" style=" margin-top: 10px;">
          <i class="fas fa-file-pdf fa-6x"></i>

          <div class="row">
            <div class="col">
              {{url.anexoDocumentoFisico.nombreArchivoOriginal}}
            </div>
          </div>

        </div>

        <div *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  "
          style=" margin-top: 10px;">
          <i class="fas fa-file-excel-o fa-6x"></i>

          <div class="row">
            <div class="col">
              {{url.anexoDocumentoFisico.nombreArchivoOriginal}}
            </div>
          </div>

        </div>

        <div *ngSwitchDefault style=" margin-top: 10px;">
          <i class="fas fa-file fa-6x"></i>

          <div class="row">
            <div class="col">
              {{url.anexoDocumentoFisico.nombreArchivoOriginal}}
            </div>
          </div>

        </div>

        <div class="row">

          <button
            (click)="eliminarImagen(url)" class="btn-primary-siadci"
            >
            <i class="fa fa-trash red"></i>
          </button>
          <button (click)="downloadFile(url.base64, url.anexoDocumentoFisico.nombreArchivoOriginal)"
            class="btn-primary-siadci">
            <i class="fa fa-download icon-blue"></i>
          </button>

        </div>
      </ng-container>


    </div>
  </ng-container>

  <ng-container *ngIf="ListaDocumentoFisico64Acuse!=null">
    <div *ngFor='let url of ListaDocumentoFisico64Acuse ' class="col-3" [ngSwitch]="url.acuseDocumentoFisico.mime">

      <ng-container *ngIf="url.acuseDocumentoFisico.ctrlActivo==true ">
        <div *ngSwitchCase="'image/png' || 'image/jpg' ">
          <img [src]="url.base64" height="100"> <br>

          <div class="row">
            <div class="col">
              {{url.acuseDocumentoFisico.nombreArchivoOriginal}}
            </div>

          </div>
        </div>

        <div *ngSwitchCase=" 'image/jpeg' ">
          <img [src]="url.base64" height="100"> <br>

          <div class="row">
            <div class="col">
              {{url.acuseDocumentoFisico.nombreArchivoOriginal}}
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'application/pdf'" style=" margin-top: 10px;">
          <i class="fas fa-file-pdf fa-6x"></i>

          <div class="row">
            <div class="col">
              {{url.acuseDocumentoFisico.nombreArchivoOriginal}}
            </div>
          </div>

        </div>

        <div *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  "
          style=" margin-top: 10px;">
          <i class="fas fa-file-excel-o fa-6x"></i>

          <div class="row">
            <div class="col">
              {{url.acuseDocumentoFisico.nombreArchivoOriginal}}
            </div>
          </div>

        </div>

        <div *ngSwitchDefault style=" margin-top: 10px;">
          <i class="fas fa-file fa-6x"></i>

          <div class="row">
            <div class="col">
              {{url.acuseDocumentoFisico.nombreArchivoOriginal}}
            </div>
          </div>

        </div>

        <div class="row">

          <button
            (click)="eliminarImagen(url)" class="btn-primary-siadci"
            >
            <i class="fa fa-trash red"></i>
          </button>
          <button (click)="downloadFile(url.base64, url.acuseDocumentoFisico.nombreArchivoOriginal)"
            class="btn-primary-siadci">
            <i class="fa fa-download icon-blue"></i>
          </button>

        </div>
      </ng-container>


    </div>
  </ng-container>
</div>
<div class="row">

  <div class="col">

    <div *ngIf="DocumentoFisico64!=null">
      <ng-container *ngIf="DocumentoFisico64.anexo!=null">
        <div class="col-3" [ngSwitch]="DocumentoFisico64.anexo.mime">

          <ng-container *ngIf="DocumentoFisico64.anexo.ctrlActivo==true ">
            <div *ngSwitchCase="'image/png' || 'image/jpg' ">
              <img [src]="DocumentoFisico64.base64" height="100"> <br>

              <div class="row">
                <div class="col">
                  {{DocumentoFisico64.anexo.nombreArchivoOriginal}}
                </div>

              </div>
            </div>

            <div *ngSwitchCase=" 'image/jpeg' ">
              <img [src]="DocumentoFisico64.base64" height="100"> <br>

              <div class="row">
                <div class="col">
                  {{DocumentoFisico64.anexo.nombreArchivoOriginal}}
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'application/pdf'" style=" margin-top: 10px;">
              <i class="fas fa-file-pdf fa-6x"></i>

              <div class="row">
                <div class="col">
                  {{DocumentoFisico64.anexo.nombreArchivoOriginal}}
                </div>
              </div>

            </div>

            <div *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  "
              style=" margin-top: 10px;">
              <i class="fas fa-file-excel-o fa-6x"></i>

              <div class="row">
                <div class="col">
                  {{DocumentoFisico64.anexo.nombreArchivoOriginal}}
                </div>
              </div>

            </div>

            <div *ngSwitchDefault style=" margin-top: 10px;">
              <i class="fas fa-file fa-6x"></i>

              <div class="row">
                <div class="col">
                  {{DocumentoFisico64.anexo.nombreArchivoOriginal}}
                </div>
              </div>

            </div>

            <div class="row">

              <button (click)="eliminarImagen(DocumentoFisico64)" class="btn-primary-siadci"
                >
                <i class="fa fa-trash red"></i>
              </button>
              <button (click)="downloadFile(DocumentoFisico64.base64, DocumentoFisico64.anexo.nombreArchivoOriginal)"
                class="btn-primary-siadci">
                <i class="fa fa-download icon-blue"></i>
              </button>

            </div>
          </ng-container>


        </div>
      </ng-container>

    </div>
  </div>

</div>
<p></p>
<div class="row" *ngIf="ListaAnexo64!=null || ListaDocumentoFisico64!=null || ListaDocumentoFisico64Acuse!=null">
  <div class="col">

    <ng-container>
      <input type='file' (change)="onSelectFile($event)" name="file-3[]" id="file-3" class="inputfile inputfile-3"
        [(value)]="inputImage" multiple>
    </ng-container>


    <label for="file-3">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
        <path
          d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
      </svg>
      <span>{{labelImage}} </span>
    </label>

  </div>
</div>
<div class="row" *ngIf="DocumentoFisico64!=null">
  <div class="col">

    <ng-container>
      <input type='file' (change)="onSelectFile($event)" name="file" id="file" class="inputfile inputfile-3"
        [(value)]="inputImage" accept="application/pdf">
    </ng-container>

    <label for="file">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
        <path
          d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
      </svg>
      <span>{{labelImage}} </span>
    </label>

  </div>
</div>
