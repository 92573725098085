import { NumberSymbol } from "@angular/common"

export class TratamientoMdl{

tratamientoId:any
ctrlCreado:Date
ctrlCreadoPor:number
ctrlActualizado:Date
ctrlActualizadoPor:number
ctrlActivo:boolean
nombre:string
descripcion: string;



constructor(){
    this.tratamientoId = null;
    this.ctrlCreado  = new Date();
    this.ctrlCreadoPor = 1;
    this.ctrlCreado = new Date();
    this.ctrlCreadoPor = 1;
    this.ctrlActivo = true;
    this.nombre = '';
    this.descripcion = '';
    
}
} 




