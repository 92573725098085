import { Component, OnInit } from "@angular/core";
import { TrazaInternaService } from "src/app/_utils/services/Service-Entidades/TrazaInternaService";
import { TrazaInternaMdl } from "src/app/_modelos/Traza_interna";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { TurnosVisorComponent } from "../turnosRecibidos-visor/turnos-visor.component";
import { VisorAdjuntosComponent } from "../visor-adjuntos/visor-adjuntos.component";
import { VisorAcusesComponent } from "../visor-acuses/visor-acuses.component";
import { OpenApiService } from "src/app/_utils/services/open-api.service";
import { DinamicoService } from "src/app/_utils/services/dinamico.service";
import { yearsData } from "src/app/_utils/interface/interfaz";

@Component({
  selector: "app-turnos-listado",
  templateUrl: "./turnos-listado.component.html",
  styleUrls: ["./turnos-listado.component.scss"],
})
export class TurnosListadoComponent implements OnInit {
  turno: TrazaInternaMdl = new TrazaInternaMdl();

  data: any[] = [];
  turnos: TrazaInternaMdl[] = [];
  p: number = 1;
  Ancho = "100%";
  mensaje: boolean;
  instruccion: boolean;
  mens = "";
  ins = "";
  seguimientoTurnos: any[] = [];

  cols: any[] = [
    //{ field: "trazaInternaId", header: "Turno" },
   // { field: "Visualizar", header: "Visualizar", width: "10%" },
    { field: "Acciones", header: "Acciones", width: "10%" },
    { field: "estatus", header: "Estatus del Turno" },
    { field: "OrigenNombre", header: "Área que Turna" },
    { field: "DestinoNombre", header: "Área Destino" },
    { field: "numeroDocumentoOriginal", header: "No. oficio original" },
    { field: "documentoAsunto", header: "Asunto Oficio" },
    { field: "cadenaFolio", header: "Folio Interno" },
    { field: "prioridad", header: "Prioridad" },
    { field: "fechaTurno", header: "Fecha del Turno" },
    { field: "agrupador", header: "Volante de Referencia" },
  ];

  colsSeguimiento: any[] = [
    { field: "estatus", header: "Estatus del Turno" },
    { field: "OrigenNombre", header: "Área que Turna" },
    { field: "DestinoNombre", header: "Área Destino" },
    { field: "numeroDocumentoOriginal", header: "No. oficio original" },
    { field: "documentoAsunto", header: "Asunto Oficio" },
    { field: "cadenaFolio", header: "Folio Interno" },
    { field: "prioridad", header: "Prioridad" },
    { field: "fechaTurno", header: "Fecha del Turno" },
    { field: "agrupador", header: "Volante de Referencia" },
  ];

  isSave: boolean = true;
  loading = false;

  /* array list that containt the years*/
  selectedYear:any;
  YearList:yearsData[] = [];

  constructor(
    private TIservice: TrazaInternaService,
    private router: Router,
    public apiService: OpenApiService,
    public dialog: MatDialog,
    private dinamicoService: DinamicoService,
  ) {}

  ngOnInit() {
    /**the paint the list when load*/
    this.YearList = this.dinamicoService.years();
    let year = this.YearList.map( (item) => item.value);//recorre the array list year
    let actualyear = {year:year[0]}//json that have the first array list
    this.find( 50, 1, actualyear)//The call function for paint list data
  }

  find(registros: number, pagina: number, actualyear:any) {
    this.loading = true;

    let numeroFinal = registros * pagina;
    let numeroInicio = numeroFinal - registros;

    this.TIservice.TrazaInternaFindallrecibidosYear(actualyear).subscribe(
      data => {
        //console.log(data);
        this.turnos = data;
        this.data = this.turnos;
        //this.Ancho = this.cols.length * 15 + "%";
        this.data.forEach((element) => {
          
          try {
            if (element.estatusTurnoId.nombre == "Turnado") {
              element.estatus = "Recibido";
            } else {
              element.estatus = element.estatusTurnoId.nombre;
            }
          } catch {
            element.estatus = "";
          }
          try {
            //Si organizacionorigen es nulo, toma campo grupo organizacional origen
            if (element.organizacionIdOrigen == null) {
              element.OrigenNombre = element.grupoOrganizacionalIdOrigen.nombre;
            } else {
              // Toma organizacionOrigen
              element.OrigenNombre = element.organizacionIdOrigen.nombre;
            }
          } catch {
            element.OrigenNombre = "";
          }
          try {
            //Si organizaciondestino es nulo, toma campo grupo organizacional destino
            if (element.organizacionIdDestino == null) {
              element.DestinoNombre = element.grupoOrganizacionalIdDestino.nombre;
              element.organizacionIdDestino = "";
            } else {
              // Toma organizacionOrigen
              element.DestinoNombre = element.organizacionIdDestino.nombre;
            }
          } catch {
            element.DestinoNombre = "";
            element.organizacionIdDestino = "";
            element.organizacionIdDestino.organizacionId = "";
          }
          try {
            element.numeroDocumentoOriginal = element.documentoFisicoId.numeroDocumentoOriginal;
          } catch {
            element.numeroDocumentoOriginal = "";
          }
          try {
            element.documentoAsunto = element.documentoFisicoId.asunto;
          } catch {
            element.documentoAsunto = "";
          }
          try {
            element.cadenaFolio = element.documentoFisicoId.cadenaFolio;
          } catch {
            element.cadenaFolio = "";
          }
          try {
            element.prioridad = element.documentoFisicoId.prioridadId.nombre
          } catch {
            element.prioridad = "";
          }
          try {
            element.fechaTurno = new Date(element.ctrlCreado).toLocaleDateString();
          } catch {
            element.fechaTurno = "";
          }
          try {
            element.agrupador = element.agrupador + "/" + "2021";
            //element.agrupador = element.agrupador;
          } catch {
            element.agrupador = "";
          }
        });

        this.loading = false;
      },
      error => {
        this.loading =false;
        console.log(error);
      }
    );

  }

  borrarTurno(tur: TrazaInternaMdl, i: number) {
    Swal.fire({
      title: "Eliminar",
      text: `Eliminará  ${tur.trazaInternaId}`,
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
    }).then((resp) => {
      if (resp.value) {
        // this.dependencias.splice(i, 1)
        this.turnos = this.turnos.filter(
          (a) => a.trazaInternaId !== tur.trazaInternaId
        );
        this.data = this.turnos.filter(
          (a) => a.trazaInternaId !== tur.trazaInternaId
        );
        tur.ctrlActivo = false;
        console.log(tur);
        this.TIservice.TrazaInternaRemove(tur.trazaInternaId).subscribe();
      }
    });
  }

  visorDocumentos(data) {
    if (data.documentoId != null) {
      this.router.navigate([
        "/turnos/visor/documento/",
        data.documentoId.documentoId,
      ]);
    } else {
      //var interfaz = this.router.navigate(['/turnos/visor/documentoFisico/', data.documentoFisicoId.documentoFisicoId]);

      const dialogRef = this.dialog.open(TurnosVisorComponent, {
        width: "50%",
        height: "90%",
        data: { id: data.documentoFisicoId.documentoFisicoId },
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }
  }

  visorAdjuntos(data) {
    let tipoDoc: String = "";

    if (data.documentoId == null) {
      tipoDoc = "documentoFisico";
    } else {
      tipoDoc = "documento";
    }
    const dialogRef = this.dialog.open(VisorAdjuntosComponent, {
      width: "50%",
      height: "90%",
      data: { id: data.documentoFisicoId.documentoFisicoId, tipoDoc: tipoDoc },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  visorAcuses(data) {
    let tipoDoc: String = "";

    if (data.documentoId == null) {
      tipoDoc = "documentoFisico";
    } else {
      tipoDoc = "documento";
    }
    const dialogRef = this.dialog.open(VisorAcusesComponent, {
      width: "50%",
      height: "90%",
      data: { id: data.documentoFisicoId.documentoFisicoId, tipoDoc: tipoDoc },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  DocRelacionado(data) {
    if (data.documentoId == null) {
      
      Swal.fire({
        title: "Generación de Oficio",
        text: "¿Esta seguro querer generar el oficio de respuesta con Folio?",
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
      }).then((resp) => {
        if (resp.value) {
            this.router.navigate([
              "/documentorelacionado/formulariofisico/" +
                data.documentoFisicoId.documentoFisicoId +
                "/new/bucketEntrada",
            ]);
        } else {
          this.ngOnInit();
        }
      },
      (error) => {
        Swal.fire({
          title: "error",
          text: "Error al generar la respuesta",
          icon: "error",
        });
      });

    } else {

      
      this.router.navigate([
        "/documentorelacionado/formulario/",
        data.documentoId.documentoId + "/new/bucketEntrada",
      ]);
    }
  }

  Mensaje(turnos) {
    this.mensaje = true;
    this.TIservice.TrazaInternaFind(Number(turnos.trazaInternaId)).subscribe(
      (resp: TrazaInternaMdl) => {
        this.mens = resp.mensaje;
      }
    );
  }

  Instrucciones(turnos) {
    this.instruccion = true;
    this.TIservice.TrazaInternaFind(Number(turnos.trazaInternaId)).subscribe(
      (resp: TrazaInternaMdl) => {
        this.ins = resp.instrucciones;
      }
    );
  }

  AsignacionPersonal(turno): void {
    const id = turno.trazaInternaId;

    if (id !== "new") {
      this.isSave = false;

      this.TIservice.TrazaInternaFind(Number(id)).subscribe(
        (resp1: TrazaInternaMdl) => {
          let peticion: Observable<any>;
          peticion = this.TIservice.TrazaInternaFind(resp1.trazaInternaId);
          peticion.subscribe(
            (resp1) => {
              Swal.fire({
                title: "Asignación de turno",
                text: "¿Esta seguro de asignarse este turno?",
                icon: "question",
                showConfirmButton: true,
                showCancelButton: true,
              }).then((resp) => {
                if (resp.value) {
                  this.AP(turno);
                } else {
                  this.ngOnInit();
                }
              });
            },

            (error) => {
              Swal.fire({
                title: "error",
                text: "error al asignar este turno",
                icon: "error",
              });
            }
          );
        }
      );
    }
  }

  AP(turno) {
    const id = turno.trazaInternaId;

    if (id !== "new") {
      this.isSave = false;

      this.TIservice.TrazaInternaFind(Number(id)).subscribe(
        (resp1: TrazaInternaMdl) => {
          let peticion: Observable<any>;
          resp1.estatusTurnoId = 7;
          peticion = this.TIservice.AutorizarEstatus(
            resp1.trazaInternaId,
            resp1
          );

          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/turnos/lista"]);
            });

          peticion.subscribe(
            (resp) => {
              Swal.fire({
                title: id,
                text: "Realizado correctamente",
                icon: "success",
              });
            },

            (error) => {
              if (error) {
              }

              Swal.fire({
                title: id,
                text: "error",
                icon: "error",
              });
            }
          );
        }
      );
    }
  }

  changeYear = (event:any) => {
    let yearChange = {year:event.value};
    this.data.length = 0;//the reset the array
    this.turnos.length = 0//the reset the array
    this.find( 50, 1, yearChange);//the call function for paint the data when change select
    this.selectedYear = event.value;
  }

  rowExpanded = (rowData:any) => {
    console.log(rowData);
    rowData.expanded = !rowData.expanded;    
    if (rowData.expanded) {
      const documentoFisicoId = rowData.documentoFisicoId.documentoFisicoId;
      const trazainterna = rowData.trazaInternaId
      //const organizacionDestinoId = rowData.organizacionIdDestino.organizacionId;
      const data = {
        documentofisicoid: documentoFisicoId,
        trazaInternaId: trazainterna
        //organizaciondestinoid :organizacionDestinoId,
      }    
      console.log(data);
      this.TIservice.TrazaInternaFindAllSeguimientoRecibidos(data).subscribe({
        next:(value) =>{
          console.log(value);
          this.seguimientoTurnos = value;
          this.seguimientoTurnos.forEach((element) => {
            try {
              if (element.estatusTurnoId.nombre == "Turnado") {
                element.estatus = "Recibido";
              } else {
                element.estatus = element.estatusTurnoId.nombre;
              }
            } catch {
              element.estatus = "";
            }
            try {
              //Si organizacionorigen es nulo, toma campo grupo organizacional origen
              if (element.organizacionIdOrigen == null) {
                element.OrigenNombre = element.grupoOrganizacionalIdOrigen.nombre;
              } else {
                // Toma organizacionOrigen
                element.OrigenNombre = element.organizacionIdOrigen.nombre;
              }
            } catch {
              element.OrigenNombre = "";
            }
            try {
              //Si organizaciondestino es nulo, toma campo grupo organizacional destino
              if (element.organizacionIdDestino == null) {
                element.DestinoNombre = element.grupoOrganizacionalIdDestino.nombre;
                element.organizacionIdDestino = "";
              } else {
                // Toma organizacionOrigen
                element.DestinoNombre = element.organizacionIdDestino.nombre;
              }
            } catch {
              element.DestinoNombre = "";
              element.organizacionIdDestino = "";
              element.organizacionIdDestino.organizacionId = "";
            }
            try {
              element.numeroDocumentoOriginal = element.documentoFisicoId.numeroDocumentoOriginal;
            } catch {
              element.numeroDocumentoOriginal = "";
            }
            try {
              element.documentoAsunto = element.documentoFisicoId.asunto;
            } catch {
              element.documentoAsunto = "";
            }
            try {
              element.cadenaFolio = element.documentoFisicoId.cadenaFolio;
            } catch {
              element.cadenaFolio = "";
            }
            try {
              element.prioridad = element.documentoFisicoId.prioridadId.nombre
            } catch {
              element.prioridad = "";
            }
            try {
              element.fechaTurno = new Date(element.ctrlCreado).toLocaleDateString();
            } catch {
              element.fechaTurno = "";
            }
            try {
              element.agrupador = element.agrupador + "/" + "2021";
              //element.agrupador = element.agrupador;
            } catch {
              element.agrupador = "";
            }
          });
          console.log(this.seguimientoTurnos);
        },
        error:(err) => {
          console.log(err);
        },
      })
    }
  }

}
