import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogDinamicoData } from 'src/app/_utils/interface/interfaz';
import { DinamicoService } from 'src/app/_utils/services/dinamico.service';

@Component({
  selector: 'app-documento-area-visor',
  templateUrl: './documento-area-visor.component.html',
  styleUrls: ['./documento-area-visor.component.scss']
})
export class DocumentoAreaVisorComponent implements OnInit {
  public pdfFormart: any;
  public archivo: any = null;
  public mime: any = null;
  public nombreArchivo: any = null;

  constructor(
    private dinamicoService: DinamicoService,   // servicio dinamico que se puede usar en cualquier endpoint
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogDinamicoData,
  ) { }

  ngOnInit(): void {
    this.dinamicoService.getIndividual("/webresources/documentofisicooriginal/minio",this.data.id).subscribe({
      next: (resp) => {
        if (resp.anexo.mime == "application/pdf") {
          this.pdfFormart = resp.base64;
        }else{
          this.archivo = resp.base64;
          this.mime = resp.anexo.mime;
          this.nombreArchivo = resp.anexo.nombreArchivoOriginal;
        }
      },
      error: (err) => {
        console.log(err); 
      },
    })
  }

  downloadFile(file:any, nombre:any) {
    const linkSource = file;
    const downloadLink = document.createElement("a");
    const fileName = nombre;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

}
