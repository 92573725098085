import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class DocumentoFisicoService {

   constructor(private http: HttpClient, ) { }

   DocumentoFindall() {
      // return this.http.get<any[]>(environment.apiURL + '/webresources/documentofisico');
      return this.http.get<any[]>(environment.apiURL + '/webresources/documentofisico/findallcustom');
   }

   DocumentoDTOFindall() {
      // return this.http.get<any[]>(environment.apiURL + '/webresources/documentofisico');
      return this.http.get<any[]>(environment.apiURL + '/webresources/documentofisicodto/findallcustom');
   }

   DocumentoFindallRange(inicio, fin) {
      return this.http.get<any[]>(environment.apiURL + '/webresources/documentofisico/' + inicio +"/" + fin);
   }

   DocumentoTurnos(entidad: any) {
      return this.http.post<any[]>(environment.apiURL + '/webresources/trazainterna/buscarfoliooriginal', entidad);
   }
 
   DocumentosByNumeroOriginal(entidad: any) {
      return this.http.post<any[]>(environment.apiURL + '/webresources/documentofisico/documentoOriginal/', entidad);
   }

   DocumentoCadenaFolio(entidad: any) {
      return this.http.post<any[]>(environment.apiURL + '/webresources/documentofisico/documentoFolio/', entidad);
   }

   BuscarCadFolio(entidad: any) {
      return this.http.post<any[]>(environment.apiURL + '/webresources/trazainterna/buscarcadenafolio', entidad);
   }

   DocumentoCreate(id: number, entidad: any) {
      return this.http.post(environment.apiURL + '/webresources/documentofisico', entidad);
   }

   DocumentoFind(id: number) {
      return this.http.get<any>(environment.apiURL + '/webresources/documentofisico/' + id);
   }

   DocumentoEdit(id: number, entidad: any) {
      return this.http.put(environment.apiURL + '/webresources/documentofisico/' + id, entidad);
   }

   DocumentoRemove(id: number) {
      return this.http.delete(environment.apiURL + '/webresources/documentofisico/' + id);
   }

   AutorizarEstatus(id:number, entidad:any) {
      return this.http.put( environment.apiURL + '/webresources/documentofisico/' + id , entidad ); 
   }

   DocumentoDTOFindallYear(year:any){
      return this.http.post<any>(environment.apiURL + '/webresources/documentofisicodto/findallcustomyear', year);
   }
   
   DocumentoCerrado(documento_cerrado:any){
      return this.http.post<any[]>(environment.apiURL + '/webresources/documentofisico/buscardocumentocerrado', documento_cerrado);
   }

   BuscarDocumentoRelacionadoId(id_documento:any){
      console.log(id_documento);
      return this.http.post(environment.apiURL + '/webresources/documentofisico/buscardocumentorelacionadoid', id_documento);
   }

   GetDocumentoArea = () => this.http.get(`${environment.apiURL}/webresources/areasgodto/findAreas`);

   documentoDTOFindAllArea = (data:any) => this.http.post(`${environment.apiURL}/webresources/documentofisicodto/findallArea`, data);

   getBucketEntradaByDocumentoFisico = (id:number) => this.http.get(`${environment.apiURL}/webresources/bucketentrada/getBucketEntradaOfDocumentoFisicoById/${id}`);
   
}

