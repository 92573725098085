
<!--<aside #mainSidebar class="main-sidebar main-sidebar-siadci">
  
  <div class="sidebar sidebar-siacdi" #side>
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="search-wrapper">
        <input
          type="search"
          class="search-input"
          [(ngModel)]="filtro"
          (change)="openApi.getMenu(filtro)"
        />
        <i class="fa fa-search"></i>
      </div>
    </div>

    <nav class="mt-2 nav-menu-siadci">
      <ul
        class="nav nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="true"
      >
        <li
          class="nav-item has-treeview menu-close"
          *ngFor="let menu of openApi.getMenuStatico(filtro)"
        >
          <a href="#" class="nav-link">
            <i class="nav-icon fas fa-list-alt"></i>
            <span class="txt-menu">
              {{ menu.titulo }}
            </span>
          </a>

          <ul class="nav nav-treeview submenu-siadci menu-close">
            <li
              *ngFor="let submenu of menu.submenu"
              [routerLink]="[submenu.url]"
              class="nav-link"
            >
              <i class="{{ submenu.icono }}"></i>
              <p>
                <a routerLinkActive="active">
                  {{ submenu.titulo }}
                </a>
              </p>
            </li>
          </ul>
        </li>

      </ul>
      <ul
        class="nav nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="true"
      >
      </ul>
    </nav>
  </div>
</aside>-->
<div id="sidebar-content-menu" >

  <div class=" mt-2 pb-0 mb-2 d-flex">
    <div class="container">
        <div class="row height d-flex justify-content-center align-items-center">
            <div class="col-md-12">
                <div class="search"> <i class="fas fa-search icon"></i> <input type="search" id="mysearch" [(ngModel)]="filtro" (change)="openApi.getMenu(filtro)" class="form-control"  placeholder="Búsqueda rápida"></div>
                <ul id="showlist" tabindex='1' class="list-group"></ul>
            </div>
        </div>
    </div>
  </div>
  <ul class="nav flex-column flex-nowrap overflow-hidden ul-nav">
      <li class="nav-item nav-li-sidebar-menu"  *ngFor="let menu of openApi.getMenuStatico(filtro); let indice = index;">
          <a class="nav-link collapsed text-truncate a-link-sedabar"  href="#menu{{indice+1}}" data-toggle="collapse" data-target="#menu{{indice+1}}">
            <div class=""><i class="nav-icon fas fa-list-alt" ></i><span class=""> {{ menu.titulo }}</span></div>
          </a>
          <div class="collapse" id="menu{{indice+1}}" aria-expanded="false">
              <ul class="flex-column nav">
                  <li class="nav-item li-submenu"
                  *ngFor="let submenu of menu.submenu"
                  [routerLink]="[submenu.url]"
                  >
                    <a class="nav-link a-sidebar" routerLinkActive="active"><span><i class="{{ submenu.icono }}"></i> {{ submenu.titulo }}</span></a>
                  </li>
              </ul>
          </div>
      </li>
  </ul>

</div>