<section class="content-header header-siadci">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <h1>Listado Documentos de Entrada </h1>
      </div>
      <div class="col-sm-6">
        <h3>Muestra los registros de Documentos de Entrada </h3>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<section class="content content-sidci">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card-siadci py-4">
      <div style="
            width: 95%;
            overflow: auto;
            margin-left: auto;
            margin-right: auto;
          ">
        <!--  <div class="table-siadci" [style.width]="Ancho">-->
        <div class="table-siadci">
          <app-paginador #paginador (change)="find( paginador.registros, paginador.page,null)"></app-paginador>

          <mat-spinner *ngIf="this.loading"></mat-spinner>

          <p-table #dt class="ng2-smart-table"
            [resizableColumns]="true" styleClass="p-datatable-gridlines" [autoLayout]="true"
            [columns]="cols" [value]="data" paginator="true" [rows]="5"
            *ngIf="!this.loading" [style]="{width:'1500px'}">

            <ng-template pTemplate="caption">
              <div class="filtros">
                <div class="filtro1">
                    <div>
                      <p-dropdown [options]="YearList" [(ngModel)]="selectedYear" (onChange)="changeYear($event)" id="select-data-anio">
                      </p-dropdown>
                    </div>
                </div>
                <div class="filtro2">
                    <div class="search-wrapper-siadci">

                      <input type="text" pInputText placeholder="Filtro global" class="search-input"
                        (input)="dt.filterGlobal($event.target.value, 'contains')" />
                      <i class="fa fa-search"></i>
                    </div>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [ngStyle]="{'width': col.width,'text-align': 'center' }">
                  <div>
                    {{ col.header }}
                  </div>
                </th>
              </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width}">

                    <p-sortIcon [field]="col.field" [pSortableColumn]="col.field"></p-sortIcon>
                  <input *ngSwitchCase="'fechaEntrada'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />

                  <input *ngSwitchCase="'folioEntrada'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />

                  <input *ngSwitchCase="'numeroDocumentoOriginal'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />

                  <input *ngSwitchCase="'documentoAsunto'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />

                  <input *ngSwitchCase="'destinatarioPuesto'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />


                  <input *ngSwitchCase="'destinatario'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                          dt.filter($event.target.value, col.field, 'contains')
                        " />

                  <input *ngSwitchCase="'prioridad'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />

                  <input *ngSwitchCase="'estatus'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />


                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">

              <tr [pSelectableRow]="rowData" [style.background]="(rowData.leido==true && band)?'#c9b77c' :''"
                (onChange)="toggleBucketEntrada(rowData)">
                <td *ngFor="let col of columns" [ngStyle]="{'width': col.width}">

                  <div style="display: flex; white-space: normal; overflow: hidden">
                    <ng-container *ngIf="col.field == 'Acciones'">
                      <div style="text-align: center;">

                        <button pButton type="button" label="Visor" class="btn-in-table main-color"
                          (click)="visorDocumentos(rowData)"></button>

                        <button *ngIf="rowData.estatus=='Finalizado'" pButton type="button"
                          label="Visor Oficio de Respuesta" class="btn-in-table outline-color"
                          (click)="visorDocumentosRelacionados(rowData)"></button>

                        <button pButton type="button" label="Visor adjuntos" class="btn-in-table main-color"
                          (click)="visorAdjuntosBE(rowData)"></button>

                   <ng-container *ngIf="apiService.BuscarPermiso('/webresources/documentofisico','post')">
                        <button
                          *ngIf="rowData.estatus=='Creado' ||  rowData.estatus=='Turnado' || rowData.estatus=='Atención Personal'"
                          pButton type="button" (click)="DocRelacionado(rowData) " label="Respuesta"
                          class="btn-in-table outline-color"></button>
                  </ng-container>
                        <!--<button pButton type="button" [routerLink]="['/bucket/formulario/'+ rowData.bucketEntradaId]"
                        icon="fas fa-reply-all" class="ui-button-info"></button>-->

                        <!--Si el estatus es Creado (nuevo doc de entrada), mostrar el botón-->
                  <ng-container *ngIf="apiService.BuscarPermiso('/webresources/trazainterna','post')">
                        <button *ngIf="rowData.estatus=='Creado' || rowData.estatus=='Turnado'" pButton type="button"
                          [routerLink]="['/bucket/formulario/'+ rowData.bucketEntradaId]" label="Turnar"
                          class="btn-in-table outline-color"></button>
                  </ng-container>

                  <ng-container *ngIf="apiService.BuscarPermiso('/webresources/documentofisico','post')">
                        <button
                          *ngIf="rowData.estatus=='Creado' || rowData.estatus=='Atendido' || rowData.estatus=='Turnado' || rowData.estatus=='Atención Personal'"
                          pButton type="button" [routerLink]="['/bucket/observacionesBE/'+ rowData.bucketEntradaId]"
                          label="Cerrar" class="btn-in-table outline-color"></button>
                  </ng-container>

                        <button type="button" *ngIf="rowData.estatus == 'Finalizado'" (click)="Mensaje(rowData)" pButton
                          icon="pi pi-external-link" label="Mensaje" class="btn-in-table outline-color"></button>
                        <p-dialog header="Mensaje" [(visible)]="mensaje" [modal]="true" [style]="{ width: '50vw' }"
                          [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
                          {{ mens }}

                        </p-dialog>

                      </div>
                    </ng-container>

                    <ng-container *ngIf="col.field == 'leido'">
                      <p-checkbox [(ngModel)]="rowData.leido" [binary]="true" label=""
                        (onChange)="toggleBucketEntrada(rowData)"></p-checkbox>
                    </ng-container>

                    <ng-container *ngIf="col.field != 'leido'">
                      {{ rowData[col.field] }}
                    </ng-container>

                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>

        </div>
      </div>
      <!-- <div class="card-footer"></div> -->
    </div>
  </div>
</section>