import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class LeyendaService {
    constructor(private http: HttpClient,) { }


    LeyendaFindall() {
        return this.http.get<any[]>(environment.apiURL + '/webresources/leyendaccp');
    }

    GetTC() {
        return this.http.get<any[]>(environment.apiURL + '/webresources/tipocontacto');
     }
  
     GetTratamiento() {
        return this.http.get<any[]>(environment.apiURL + '/webresources/tratamiento');
     }

    LeyendaCreate(entidad: any) {
        return this.http.post(environment.apiURL + '/webresources/leyendaccp', entidad);
    }

    LeyendaFind(id: number) {
        return this.http.get<any>(environment.apiURL + '/webresources/leyendaccp/' + id);
    }

    LeyendaEdit(id: number, entidad: any) {
        return this.http.put(environment.apiURL + '/webresources/leyendaccp/' + id, entidad);
    }

    LeyendaRemove(id: number) {
        return this.http.delete(environment.apiURL + '/webresources/leyendaccp/' + id);
    }  

}