<!-- Content Header (Page header) -->
<section class="content-header header-siadci">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <h1>Listado de documentos</h1>
      </div>
      <div class="col-sm-6">
        <h3>Muestra los registros de documentos</h3>
      </div>
      <div class="col-sm-6 text-right">
        <button routerLink="/documento/formulario/new" class="btn-primary-siadci">
          <i class="fa fa-plus"></i> Agregar
        </button>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content content-sidci">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card-siadci py-4">
      <!-- <div class="card-body"> -->
      <div style="
            width: 95%;
            overflow: auto;
            margin-left: auto;
            margin-right: auto;
          ">
      
      </div>
      <!-- </div> -->
      <!-- /.card-body -->
      <!-- <div class="card-footer"></div> -->
      <!-- /.card-footer-->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->