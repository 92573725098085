<!--<section class="content-header header-siadci">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <h1>Listado Turnos Enviados</h1>
      </div>
      <div class="col-sm-6">
        <h3 class="card-title">Muestra los registros de Turnos Enviados</h3>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
<!--</section>

<section class="content content-sidci">
  <!-- Default box -->
<!--<div class="container-fluid">
    <div class="card-siadci py-4">
      <div class="table-siadci">
        <p-table
          #dt
          class="ng2-smart-table"
          [columns]="cols"
          [value]="data"
          paginator="true"
          [rows]="10"
        >
          <ng-template pTemplate="caption">
            <!-- <div style="text-align: left">
              <i class="fa fa-search" style="margin: 4px 4px 0 0"></i>
              <input
                type="text"
                pInputText
                size="100"
                placeholder="Filtro global"
                (input)="dt.filterGlobal($event.target.value, 'contains')"
                style="width: auto"
              />
            </div> -->
<!--<div class="search-wrapper-siadci d-flex">
              <input
                type="text"
                pInputText
                placeholder="Filtro global"
                class="search-input"
                (input)="dt.filterGlobal($event.target.value, 'contains')"
              />
              <i class="fa fa-search"></i>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field">
                {{ col.header }}
                <p-sortIcon
                  [field]="col.field"
                  [pSortableColumn]="col.field"
                ></p-sortIcon>

     

                <input
                  *ngSwitchCase="'fechaTurno'"
                  pInputText
                  type="text"
                  placeholder="Contiene"
                  class="ui-column-filter"
                  style="width: 100%"
                  (input)="
                    dt.filter($event.target.value, col.field, 'contains')
                  "
                />

                <input
                *ngSwitchCase="'cadenaFolio'"
                pInputText
                type="text"
                placeholder="Contiene"
                class="ui-column-filter"
                style="width: 100%"
                (input)="
                  dt.filter($event.target.value, col.field, 'contains')
                "
              />
  
                <input
                  *ngSwitchCase="'numeroDocumentoOriginal'"
                  pInputText
                  type="text"
                  placeholder="Contiene"
                  class="ui-column-filter"
                  style="width: 100%"
                  (input)="
                    dt.filter($event.target.value, col.field, 'contains')
                  "
                />

                <input
                  *ngSwitchCase="'OrigenNombre'"
                  pInputText
                  type="text"
                  placeholder="Contiene"
                  class="ui-column-filter"
                  style="width: 100%"
                  (input)="
                    dt.filter($event.target.value, col.field, 'contains')
                  "
                />

                <input
                  *ngSwitchCase="'DestinoNombre'"
                  pInputText
                  type="text"
                  placeholder="Contiene"
                  class="ui-column-filter"
                  style="width: 100%"
                  (input)="
                    dt.filter($event.target.value, col.field, 'contains')
                  "
                />

                <input
                  *ngSwitchCase="'agrupador'"
                  pInputText
                  type="text"
                  placeholder="Contiene"
                  class="ui-column-filter"
                  style="width: 100%"
                  (input)="
                    dt.filter($event.target.value, col.field, 'contains')
                  "
                />

                <input
                  *ngSwitchCase="'documentoAsunto'"
                  pInputText
                  type="text"
                  placeholder="Contiene"
                  class="ui-column-filter"
                  style="width: 100%"
                  (input)="
                    dt.filter($event.target.value, col.field, 'contains')
                  "
                />

                <input
                  *ngSwitchCase="'estatus'"
                  pInputText
                  type="text"
                  placeholder="Contiene"
                  class="ui-column-filter"
                  style="width: 100%"
                  (input)="
                    dt.filter($event.target.value, col.field, 'contains')
                  "
                />
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
              <td *ngFor="let col of columns">
                <div
                  style="display: flex; white-space: normal; overflow: hidden"
                >
                  <ng-container *ngIf="col.field == 'Acciones'">
                    <div style="display: block">
                      <button
                        pButton
                        type="button"
                        label="Visor"
                        class="btn-in-table main-color"
                        (click)="visorDocumentos(rowData)"
                      ></button>

                      <button
                        type="button"
                        *ngIf="rowData.estatus == 'Respuesta'"
                        (click)="Mensaje(rowData)"
                        pButton
                        icon="pi pi-external-link"
                        label="Mensaje"
                        class="btn-in-table outline-color"
                      ></button>
                      <p-dialog
                        header="Mensaje"
                        [(visible)]="mensaje"
                        [modal]="true"
                        [style]="{ width: '50vw' }"
                        [maximizable]="true"
                        [baseZIndex]="10000"
                        [draggable]="false"
                        [resizable]="false"
                      >
                        {{ mens }}


                      </p-dialog>

                      <button
                        pButton
                        type="button"
                        (click)="Instrucciones(rowData)"
                        pButton
                        class="btn-in-table outline-color"
                        icon="pi pi-external-link"
                        label="Instrucciones"
                      ></button>
                      <p-dialog
                        header="Instrucciones"
                        [(visible)]="instruccion"
                        [modal]="true"
                        [style]="{ width: '50vw' }"
                        [maximizable]="true"
                        [baseZIndex]="10000"
                        [draggable]="false"
                        [resizable]="false"
                      >
                        {{ ins }}
                      </p-dialog>

                      <!--Si el estatus es Atendido, mostrar el botón-->
<!--<button
                        *ngIf="rowData.estatus == 'Respuesta'"
                        pButton
                        type="button"
                        (click)="DocRelacionado(rowData)"
                        label="Responder oficio"
                        class="btn-in-table outline-color"
                      ></button>

                      <!--
                        ** No se puede "turnar" un turno enviado **

                         <button pButton type="button" [routerLink]="['/turnos/formularios/'+ rowData.trazaInternaId]"
                          label="Turnar" class="ui-button-success"></button>
                        -->

<!--<button
                        *ngIf="rowData.estatus != 'Cerrado'"
                        pButton
                        type="button"
                        (click)="borrarTurno(rowData, i)"
                        label="Eliminar Turno"
                        class="btn-in-table outline-color"
                      ></button>

                      <!--  <button pButton type="button" label="Cerrar" (click)='CerrarTurno(rowData)'></button>-->

<!--<button
                        *ngIf="rowData.estatus != 'Cerrado'"
                        pButton
                        type="button"
                        [routerLink]="[
                          '/turnos/observaciones/' + rowData.trazaInternaId
                        ]"
                        label="Cerrar Turno"
                        class="btn-in-table outline-color"
                      ></button>
                    </div>
                  </ng-container>
                  {{ rowData[col.field] }}
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</section>-->

<section class="content-header header-siadci">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <h1>Listado Turnos Enviados</h1>
      </div>
      <div class="col-sm-6">
        <h3 class="card-title">Muestra los registros de Turnos Enviados (Totales)</h3>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<section class="content content-sidci">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card-siadci py-4">
      <div style="
            width: 95%;
            overflow: auto;
            margin-left: auto;
            margin-right: auto;
          ">
        <div class="table-siadci" >

          <app-paginador #paginador (change)="find( paginador.registros, paginador.page, null)"></app-paginador>

          <mat-spinner *ngIf="this.loading"></mat-spinner>

          <p-table #dt class="ng2-smart-table"
            [resizableColumns]="true" styleClass="p-datatable-gridlines" [autoLayout]="true"
            [columns]="cols" [value]="data" paginator="true" [rows]="5"
            *ngIf="!this.loading" [style]="{width:'1500px'}">
            <ng-template pTemplate="caption">
              <!-- <div style="text-align: left">
                  <i class="fa fa-search" style="margin: 4px 4px 0 0"></i>
                  <input
                    type="text"
                    pInputText
                    size="100"
                    placeholder="Filtro global"
                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                    style="width: auto"
                  />
                </div> -->
              <div class="filtros">
                <div class="filtro1">
                  <p-dropdown [options]="YearList" [(ngModel)]="selectedYear" (onChange)="changeYear($event)" id="select-data-anio">
                  </p-dropdown>
                </div>
                <div class="filtro2">
                  <div class="search-wrapper-siadci d-flex">
                    <input type="text" pInputText placeholder="Filtro global" class="search-input"
                      (input)="dt.filterGlobal($event.target.value, 'contains')" />
                    <i class="fa fa-search"></i>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [ngStyle]="{'width': col.width,'text-align': 'center' }">
                <!--  <ng-container *ngIf="col.field == 'Visualizar'">
                      <i class="pi pi-tag"></i>
                  </ng-container>-->
                  {{ col.header }}
                </th>
              </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field">
                  <p-sortIcon [field]="col.field" [pSortableColumn]="col.field"></p-sortIcon>

                  <input *ngSwitchCase="'fechaTurno'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />

                  <input *ngSwitchCase="'cadenaFolio'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />

                  <input *ngSwitchCase="'numeroDocumentoOriginal'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />

                  <input *ngSwitchCase="'OrigenNombre'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />

                  <input *ngSwitchCase="'DestinoNombre'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />



                  <input *ngSwitchCase="'agrupador'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />

                  <input *ngSwitchCase="'documentoAsunto'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />

                  <input *ngSwitchCase="'estatus'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                        dt.filter($event.target.value, col.field, 'contains')
                      " />



                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
              <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns">
                  <div style="display: flex; white-space: normal; overflow: hidden">
                    <ng-container *ngIf="col.field == 'Visualizar'">
                        <div style="text-align: center;">
                          <div>
                            <button type="button" (click)="rowExpanded(rowData)" class="btn" >
                              <i class="pi" [ngClass]="rowData.expanded ? 'pi-chevron-down' : 'pi-chevron-right'"></i>
                            </button>
                          </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="col.field == 'Acciones'">
                      <div style="text-align: center;">

                        <button pButton type="button" label="Visor" class="btn-in-table main-color"
                          (click)="visorDocumentos(rowData)"></button>

                        <button pButton type="button" label="Adjuntos" class="btn-in-table main-color"
                          (click)="visorAdjuntos(rowData)"></button>

                        <button *ngIf="  (rowData.organizacionIdOrigen != null
                                      && rowData.organizacionIdOrigen.organizacionId == 3)"
                        pButton type="button" class="btn-in-table outline-color" label="Indicador" 
                        routerLink="/documentofisico/indicador/{{rowData.documentoFisicoId.documentoFisicoId}}"></button>

                        <button type="button" *ngIf="rowData.estatus == 'Respuesta' || rowData.mensaje != ''"
                          (click)="Mensaje(rowData)" pButton icon="pi pi-external-link" label="Mensaje"
                          class="btn-in-table outline-color"></button>
                        <p-dialog header="Mensaje" [(visible)]="mensaje" [modal]="true" [style]="{ width: '50vw' }"
                          [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
                          {{ mens }}


                        </p-dialog>

                        <button pButton type="button" (click)="Instrucciones(rowData)" pButton
                          class="btn-in-table outline-color" icon="pi pi-external-link" label="Instrucciones"></button>
                        <p-dialog header="Instrucciones" [(visible)]="instruccion" [modal]="true"
                          [style]="{ width: '50vw' }" [maximizable]="true" [baseZIndex]="10000" [draggable]="false"
                          [resizable]="false">
                          {{ ins }}
                        </p-dialog>

                        <!--Si el estatus es Atendido, mostrar el botón-->
                        <button *ngIf="rowData.estatus == 'Respuesta'" pButton type="button"
                          (click)="DocRelacionado(rowData)" label="Responder oficio"
                          class="btn-in-table outline-color"></button>

                        <!--
                        ** No se puede "turnar" un turno enviado **

                         <button pButton type="button" [routerLink]="['/turnos/formularios/'+ rowData.trazaInternaId]"
                          label="Turnar" class="ui-button-success"></button>
                        -->

                        <button *ngIf="rowData.estatus != 'Cerrado'" pButton type="button"
                          (click)="borrarTurno(rowData, i)" label="Eliminar Turno"
                          class="btn-in-table outline-color"></button>

                        <!--  <button pButton type="button" label="Cerrar" (click)='CerrarTurno(rowData)'></button>-->

                        <button *ngIf="rowData.estatus != 'Cerrado'" pButton type="button" [routerLink]="[
                          '/turnos/observaciones/' + rowData.trazaInternaId
                        ]" label="Cerrar Turno" class="btn-in-table outline-color"></button>


                      </div>
                    </ng-container>
                    {{ rowData[col.field] }}
                  </div>
                </td>
              </tr>
              <tr *ngIf="rowData.expanded">
                <td colspan="10" class="m-0">
                  <div>
                    <p-table [value]="seguimientoTurnos" [columns]="colsSeguimiento">
                      <ng-template pTemplate="header" let-columns>
                      <tr>
                          <th *ngFor="let col of columns" [ngStyle]="{'width': col.width,'text-align': 'center' }">
                          {{ col.header }}
                          </th>
                      </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowDataSeguimiento let-columns="columns">
                          <tr [pSelectableRow]="rowDataSeguimiento">
                              <td *ngFor="let col of columns">
                                  {{rowDataSeguimiento[col.field]}}
                              </td>
                          </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <!-- <div class="card-footer"></div> -->
    </div>
  </div>
</section>