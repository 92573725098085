<section class="content-header header-siadci">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <h1>Reporte Documentos sin Acuse </h1>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  
  <!-- Main content -->
  <section class="content content-sidci">
    <!-- Default box -->
    <div class="container-fluid">
      <!-- <div class="siadci-content-wrapper pt-3"> -->
  
      <div class="siadci-content-wrapper">
        <!-- <div class="container-fluid"> -->
        <form [formGroup]="formGroup">
          <div class="box-body">
            <ng-template matStepLabel>Formulario</ng-template>

            <div class="section-siadci py-3 col-12">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Remitente</mat-label>

                <input
                  matInput
                  style="display: none"
                  formControlName="organizacion"
                  required
                />

                <p style="height: 14px"></p>

                <p-dropdown
                  [options]="ListadoContacto"
                  filter="true"
                  optionLabel="NombreCompleto"
                  formControlName="organizacion"
                >
                </p-dropdown>

                <mat-error *ngIf="f.organizacion.hasError('required')">
                  <strong>Este valor </strong> es requerido.
                </mat-error>
              </mat-form-field>
            </div>

  
             
  
            <!--<div class="col-sm-4 col-lg-4">
              <app-dropdown #selectOrganizasion rutaClase="#/components/schemas/Organizacion" nombreCampo="organizacionId"
                [nombreClase]="'Organizacion'" style="width: 100%" BusquedaCustom="true" (editControl)="f.organizacionId.setValue($event)" >
              </app-dropdown>
  
            </div>-->

          </div>
          <div class="box-footer-siadci">
            <button class="btn-primary-siadci" (click)="Pdf()">
              Vista Previa
            </button>
          </div>
        </form>
  
        <div style="height: 50px"></div>
  
        <div *ngIf="pdfFormart != null">
          <ngx-extended-pdf-viewer [src]="pdfFormart" useBrowserLocale="true" height="80vh">
          </ngx-extended-pdf-viewer>
        </div>
  
        <!-- /.row -->
        <!-- </div> -->
      </div>
      <!-- /.siadci-content-wrapper -->
      <!-- </div> -->
    </div>
    <!-- /.card -->
  </section>
  <!-- /.content -->