import {Component, OnInit} from '@angular/core';
import { OpenApiService } from 'src/app/_utils/services/open-api.service';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { LeyendaMdl } from 'src/app/_modelos/Leyenda';
import { LeyendaService } from 'src/app/_utils/services/Service-Entidades/Leyenda';
import { Router } from '@angular/router';

@Component ({
    selector:'app-listadoLeyenda',
    templateUrl:'./listadoLeyenda.component.html',
    styleUrls:['./listadoLeyenda.component.scss']
})


export class ListadoLeyendaComponent implements OnInit {

    LEYE: LeyendaMdl = new LeyendaMdl();
    data:any[] = []
    tle: LeyendaMdl[]=[]
    Ancho = "100%";
    p:number = 1
    usuario: any;


    cols:any[]=[

        {field:"leyendaCcpId", header:"Leyenda"},
        {field:"nombre", header:"Nombre"},
        {field:"descripcion", header:"Descripción"},
        {field:"Acciones", header:"Acciones"},

    ];

    isSave: boolean = true

    constructor(private leyservice: LeyendaService,
      public apiService: OpenApiService,
      private router: Router){ }


      ngOnInit(){
        this.usuario = this.apiService.getCurrentUser();
        this.leyservice.LeyendaFindall()
        .subscribe(resp => {
            this.tle = resp
            this.tle = this.tle.filter(a => a.ctrlActivo == true)
            this.data = this.tle;     

            this.data.forEach(element => {
                
                try {
                    element.nombre = element.nombre
                  } catch{
                    element.nombre = "";
                  }

                  try {
                    element.descripcion = element.descripcion
                  } catch{
                    element.descripcion = "";
                  }

                  try {
                    element.organizacionnombre = element.organizacionId.nombre
                  } catch{
                    element.organizacionnombre = "";
                  }

                  try {
                    element.grupoorganizacionalnombre = element.grupoOrganizacionalId.nombre
                  } catch{
                    element.grupoorganizacionalnombre = "";
                  }


            })

        })
    }

    borrarLey(leye: LeyendaMdl,  i:number){
        Swal.fire({
          title:'Eliminar',
          text:`Eliminará  ${leye.leyendaCcpId}`,
          icon: 'question',
          showConfirmButton:true,
          showCancelButton:true
        }).then(resp => {
          if(resp.value){
            // this.dependencias.splice(i, 1)
            this.tle = this.tle.filter( b => b.leyendaCcpId !== leye.leyendaCcpId)
            this.data = this.tle.filter(b => b.leyendaCcpId !== leye.leyendaCcpId)
            leye.ctrlActivo = false;
            console.log(leye)
            
            this.leyservice.LeyendaRemove(
                leye.leyendaCcpId,
                
              ).subscribe()
          }
        })
      }



}