<section class="content-header header-siadci">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <h1>Listado Turnos Tree</h1>
      </div>
      <div class="col-sm-6">
        <h3>Muestra los registros de Turnos Recibidos (Totales)</h3>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<section class="content content-sidci">
    <div class="container-fluid">
        <div class="card-siadci py-4">
            <div style="
            width: 95%;
            overflow: auto;
            margin-left: auto;
            margin-right: auto;
            ">
                <div class="table-siadci">
                    <!--div class="card">
                        <p-treeTable [value]="data" [scrollable]="true" [style]="{'min-width':'50rem'}">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Name</th>
                                    <th>Name</th>
                                    <th>Size</th>
                                    <th>Type</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                                <tr [ttRow]="rowNode">
                                    <td>
                                        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                    </td>
                                    <td>
                                        {{ rowData.name }}
                                    </td>
                                    <td>{{ rowData.size }}</td>
                                    <td>{{ rowData.type }}</td>
                                </tr>
                            </ng-template>
                        </p-treeTable>

                    </div-->
                   
                    <!--div class="">
                        <p-table [value]="ListTurnos" dataKey="name" [style]="{ 'min-width': '60rem' }">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 5rem"></th>
                                    <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
                                    <th>Image</th>
                                    <th pSortableColumn="price">Price <p-sortIcon field="price"></p-sortIcon></th>
                                    <th pSortableColumn="category">Category <p-sortIcon field="category"></p-sortIcon></th>
                                    <th pSortableColumn="rating">Reviews <p-sortIcon field="rating"></p-sortIcon></th>
                                    <th pSortableColumn="inventoryStatus">Status <p-sortIcon field="inventoryStatus"></p-sortIcon></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-product let-expanded="expanded">
                                <tr>
                                    <td>
                                        <button type="button" pButton pRipple [pRowToggler]="product" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                    </td>
                                    <td>{{ product.DestinoNombre }}</td>
                                    <td> {{ product.OrigenNombre }}</td>
                                    <td>{{ product.agrupador }}</td>
                                    <td>{{ product.cadenaFolio }}</td>
                                    <td></td>
                                    <td>
                                        <p-tag [value]="product.inventoryStatus"></p-tag>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="rowexpansion" let-product>
                                <tr>
                                    <td colspan="7">
                                        <div class="p-3">
                                            <p-table [value]="product.orders" dataKey="id">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th pSortableColumn="id">Id <p-sortIcon field="price"></p-sortIcon></th>
                                                        <th pSortableColumn="customer">Customer <p-sortIcon field="customer"></p-sortIcon></th>
                                                        <th pSortableColumn="date">Date <p-sortIcon field="date"></p-sortIcon></th>
                                                        <th pSortableColumn="amount">Amount <p-sortIcon field="amount"></p-sortIcon></th>
                                                        <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
                                                        <th style="width: 4rem"></th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-order>
                                                    <tr>
                                                        <td>{{ order.id }}</td>
                                                        <td>{{ order.customer }}</td>
                                                        <td>{{ order.id }}</td>
                                                        <td>{{ order.amount | currency: 'USD' }}</td>
                                                        <td>
                                                            <p-tag [value]="order.status" [severity]="getStatusSeverity(order.status)"></p-tag>
                                                        </td>
                                                        <td><p-button type="button" icon="pi pi-plus"></p-button></td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td colspan="6">There are no order for this product yet.</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>-->
                    
                    <p-table [value]="lazyloadTurnos" [autoLayout]="true" 
                    [columns]="cols" [style]="{width:'1500px'}" 
                    [paginator]="true" [rows]="5" [lazy]="true" (onLazyLoad)="loadTurnos($event)" [totalRecords]="totalRecords" [loading]="loading" >
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" class="text-center" [ngStyle]="{'width': col.width,'text-align': 'center' }">
                                <ng-container *ngIf="col.field == 'Visualizar'">
                                    <i class="pi pi-tag"></i>
                                </ng-container>
                                {{ col.header }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
                            <tr [pSelectableRow]="rowData">
                                <td *ngFor="let col of columns">
                                    <ng-container *ngIf="col.field == 'Visualizar'" >
                                        <div style="text-align: center;">
                                            <!--button type="button" (click)="rowExpanded(rowData)" class="btn btn-primary" >
                                                <i class="pi" [ngClass]="rowData.expanded ? 'pi-chevron-down' : 'pi-chevron-right'"></i>
                                            </button>-->
                                            <div class="">
                                                <button mat-icon-button class="" (click)="rowExpanded(rowData)" >
                                                    <i class="pi" [ngClass]="rowData.expanded ? 'pi-chevron-down' : 'pi-chevron-right'"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="col.field == 'Acciones'">
                                        <div style="text-align: center;">
                                            <button pButton type="button" label="Visor" class="btn-in-table main-color"
                                            (click)="visorDocumentos(rowData)"></button>

                                            <button pButton type="button" label="Adjuntos" class="btn-in-table main-color"
                                            (click)="visorAdjuntos(rowData)"></button>
                                        </div>
                                    </ng-container>
                                    {{rowData[col.field]}}
                                </td>
                                <!--td>{{ rowData.DestinoNombre }}</td>
                                <td>{{ rowData.OrigenNombre }}</td>
                                <td>{{ rowData.grupador }}</td>
                                <td>{{ rowData.cadenaFolio }}</td-->
                            </tr>
                            <tr *ngIf="rowData.expanded">
                                <td colspan="11" class="m-0">
                                    <div class="">
                                        <!--table class="table">
                                            <thead>
                                                <tr>
                                                <th scope="col">Estatus del Turno</th>
                                                <th scope="col">Área que Turna</th>
                                                <th scope="col">Área Destino</th>
                                                <th scope="col">No. oficio original</th>
                                                <th scope="col">Asunto Oficio</th>
                                                <th scope="col">Folio Interno</th>
                                                <th scope="col">Prioridad</th>
                                                <th scope="col">Fecha del Turno</th>
                                                <th scope="col">Volante de Referencia</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <th scope="row">1</th>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>@mdo</td>
                                                <td>@mdo</td>
                                                <td>@mdo</td>
                                                <td>@mdo</td>
                                                <td>@mdo</td>
                                                </tr>
                                                <tr>
                                                <th scope="row">2</th>
                                                <td>Jacob</td>
                                                <td>Thornton</td>
                                                <td>@fat</td>
                                                <td>@mdo</td>
                                                <td>@mdo</td>
                                                <td>@mdo</td>
                                                <td>@mdo</td>
                                                <td>@mdo</td>
                                                </tr>
                                            </tbody>
                                        </table>-->
                                        
                                        <p-table [value]="seguimientoTurnos" [columns]="colsSeguimiento">
                                            <ng-template pTemplate="header" let-columns>
                                                <!--tr>
                                                    <th scope="col">Estatus del Turno</th>
                                                    <th scope="col">Área que Turna</th>
                                                    <th scope="col">Área Destino</th>
                                                    <th scope="col">No. oficio original</th>
                                                    <th scope="col">Asunto Oficio</th>
                                                    <th scope="col">Folio Interno</th>
                                                    <th scope="col">Prioridad</th>
                                                    <th scope="col">Fecha del Turno</th>
                                                    <th scope="col">Volante de Referencia</th>
                                                </tr>-->
                                            <tr>
                                                <th *ngFor="let col of columns" [ngStyle]="{'width': col.width,'text-align': 'center' }">
                                                {{ col.header }}
                                                </th>
                                            </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowDataSeguimiento let-columns="columns">
                                                <tr [pSelectableRow]="rowDataSeguimiento">
                                                    <td *ngFor="let col of columns">
                                                        {{rowDataSeguimiento[col.field]}}
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</section>
    
