export class TipoActorMdl{
tipoActorId:number
ctrlCreado:Date
ctrlCreadoPor:number
ctrlActualizado:Date
ctrlActualizadoPor:number
ctrlActivo:boolean
nombre:string
descripcion:string
} 
