import {Component, OnInit} from '@angular/core';
import { OpenApiService } from 'src/app/_utils/services/open-api.service';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { TipoContactoMdl } from 'src/app/_modelos/TipoContacto';
import { TipoContactoService } from 'src/app/_utils/services/Service-Entidades/tipoContacto.service';
import { Router } from '@angular/router';

@Component ({
    selector:'app-listado-tipoContacto',
    templateUrl:'./listado-tipoContacto.component.html',
    styleUrls:['./listado-tipoContacto.component.scss']
})


export class ListadotipoContactoComponent implements OnInit {

    TCO: TipoContactoMdl = new TipoContactoMdl();
    data:any[] = []
    tpc: TipoContactoMdl[]=[]
    Ancho = "100%";
    p:number = 1
    usuario: any;


    cols:any[]=[

        {field:"tipoContactoId", header:"Tipo Contacto"},
        {field:"nombre", header:"Nombre"},
        {field:"descripcion", header:"Descripción"},
        {field:"Acciones", header:"Acciones"},

    ];

    isSave: boolean = true

    constructor(private TCservice: TipoContactoService,
      public apiService: OpenApiService,
      private router: Router){ }


      ngOnInit(){
        this.usuario = this.apiService.getCurrentUser();
        this.TCservice.TipoContactoFindall()
        .subscribe(resp => {
            this.tpc = resp
            this.tpc = this.tpc.filter(a => a.ctrlActivo == true)
            this.data = this.tpc;     

            this.data.forEach(element => {
                
                try {
                    element.nombre = element.nombre
                  } catch{
                    element.nombre = "";
                  }

                  try {
                    element.descripcion = element.descripcion
                  } catch{
                    element.descripcion = "";
                  }

                  try {
                    element.organizacionnombre = element.organizacionId.nombre
                  } catch{
                    element.organizacionnombre = "";
                  }

                  try {
                    element.grupoorganizacionalnombre = element.grupoOrganizacionalId.nombre
                  } catch{
                    element.grupoorganizacionalnombre = "";
                  }


            })

        })
    }

    borrarTipoCon(tct: TipoContactoMdl,  i:number){
        Swal.fire({
          title:'Eliminar',
          text:`Eliminará  ${tct.tipoContactoId}`,
          icon: 'question',
          showConfirmButton:true,
          showCancelButton:true
        }).then(resp => {
          if(resp.value){
            // this.dependencias.splice(i, 1)
            this.tpc = this.tpc.filter( b => b.tipoContactoId !== tct.tipoContactoId)
            this.data = this.tpc.filter(b => b.tipoContactoId !== tct.tipoContactoId)
            tct.ctrlActivo = false;
            console.log(tct)
            this.TCservice.TipoContactoRemove(
                tct.tipoContactoId,
                
              ).subscribe()
          }
        })
      }



}