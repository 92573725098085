<h1 mat-dialog-title>Hi {{data.name}}</h1>
<div mat-dialog-content>
    <p>What's your favorite animal?</p>
    <mat-form-field>
        <mat-label>Favorite Animal</mat-label>
        <input matInput [(ngModel)]="data.animal">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>