export class LeyendaMdl{
    
    leyendaCcpId:number;
    ctrlCreado:Date
    ctrlCreadoPor:number
    ctrlActualizado:Date
    ctrlActualizadoPor:number
    ctrlActivo:boolean
    nombre:string
    descripcion:string
   

    constructor(){

        this.leyendaCcpId = null;
        this.ctrlCreado = null;
        this.ctrlCreadoPor = null;
        this.ctrlActualizado = null;
        this.ctrlActualizadoPor = null;
        this.ctrlActivo = null;
        this.nombre = null;
        this.descripcion = null;
      
        
        
    }

} 

