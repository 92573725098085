<app-header (toggleMenuSidebar)="toggleMenuSidebar()"></app-header>

<!--<div class="wrapper"  id="contenedor-main">

  <app-menu-sidebar
    #sideBar
    (click)="AlturaMenu()"
    (mainSidebarHeight)="mainSidebarHeight($event)"></app-menu-sidebar>

  <div
    class="content-wrapper"
    #contentWrapper style="height: fit-content;"
    [style.minHeight]="alturaMenu">




    <router-outlet></router-outlet>
  </div>

  <aside class="control-sidebar control-sidebar-dark"></aside>
  <div id="sidebar-overlay" (click)="toggleMenuSidebar()"></div>
</div>-->

<div class="container-fluid">
  <div class="row">
    <div class="collapse show bg-light pt-2 pl-0 min-vh-100 size-div-content_1" id="sidebar" >
        <app-menu-sidebar #sideBar></app-menu-sidebar>
    </div>
    <div id="content-main" class="size-div-content_2 pt-0">
        <router-outlet></router-outlet>
    </div>
  </div>
</div>

