
<section style="margin-top: 15px;">

  <div class="content">
    <div class="container-fluid">

      <div class="row">
        <div class="col-lg-6">

          <div class="card bg-sidci-info" style="">
            <div class="card-header border-0">
            </div>
            <div class="card-body">
              <div class="">
                  <div class="row">
                    <div class="col text-center">
                        <div>
                          <img class="dashboard-icon" src="../../../assets/img/dashboard-news.png" width="100" height="100" class="img-fluid">
                        </div>
                    </div>
                    <div class="col text-center col-card-info">
                      <div class="" style="margin-top: 25px;">
                        <p class="p-card">{{notificaciones.nuevos}}</p>
                        <p class="p-card">Nuevos</p>
                      </div>
                    </div>
                  </div>
              </div>

            </div>
            <div class="card-footer text-center"></div>
            <div>
              <div class="text-center bg-footer-info">
                <a href="bucket/lista" style="" class="small-box-footer"><span class="a-link-card">más información </span>  <i class="i-icon-card fas fa-arrow-circle-right"></i></a>
              </div>
            </div>
          </div>

          <div class="card bg-sidci-warning">
            <div class="card-header border-0">
              <!--<h3 class="card-title">Products</h3>
              <div class="card-tools">
              <a href="#" class="btn btn-tool btn-sm">
              <i class="fas fa-download"></i>
              </a>
              <a href="#" class="btn btn-tool btn-sm">
              <i class="fas fa-bars"></i>
              </a>
              </div>-->
            </div>
            <div class="card-body">
              <div class="">
                    <div class="row">
                      
                      <div class="col text-center">
                          <div>
                            <img class="dashboard-icon" src="../../../assets/img/dashboard-pendings.png" width="100" height="100" class="img-fluid">
                          </div>
                      </div>
                      <div class="col text-center col-card-warning">
                        <div class="" style="margin-top: 25px;">
                          <p class="p-card">{{notificaciones.pendientes}}</p>
                          <p class="p-card">Pendientes</p>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-center "></div>
            <div>
              <div class="bg-footer-warning text-center" style="">
                <a href="DocXfirmar/lista" style="" class="small-box-footer"><span class="a-link-card">más información </span>  <i class="i-icon-card fas fa-arrow-circle-right"></i></a>
              </div>
            </div>
          </div>

        </div>

        <div class="col-lg-6">
          <div class="card bg-sidci-success">
            <div class="card-header border-0">

            </div>
            <div class="card-body">
              <div class="">
                  <div class="row">
                    
                    <div class="col text-center">
                        <div>
                          <img class="dashboard-icon" src="../../../assets/img/dashboard-sends.png" width="100" height="100" class="img-fluid">
                        </div>
                    </div>
                    <div class="col text-center col-card-success">
                      <div class="" style="margin-top: 25px;">
                        <p class="p-card">{{notificaciones.enviados}}</p>
                        <p class="p-card">Enviados</p>
                      </div>
                    </div>
                  </div>
              </div>


            </div>
            <div class="card-footer text-center "></div>
            <div>
              <div class="bg-footer-success text-center" style="">
                <a href="bucketsalida/lista" style="" class="small-box-footer"><span class="a-link-card">más información </span>  <i class="i-icon-card fas fa-arrow-circle-right"></i></a>
              </div>
            </div>
          </div>

          <div class="card bg-sidci-danger" style="">
            <div class="card-header border-0">

            </div>
            <div class="card-body">
              <div class="">
                  <div class="row">
                    
                    <div class="col text-center">
                        <div>
                          <img class="dashboard-icon" src="../../../assets/img/dashboard-urgents.png" width="100" height="100" class="img-fluid">
                        </div>
                    </div>
                    <div class="col text-center col-card-danger">
                      <div class="" style="margin-top: 25px;">
                        <p class="p-card">{{notificaciones.urgentes}}</p>
                        <p class="p-card">Urgentes</p>
                      </div>
                    </div>
                  </div>
              </div>

            </div>
            <div class="card-footer text-center"></div>
            <div>
              <div class="bg-footer-danger text-center" style="">
                <a href="bucket/ListaUrgentes" style="" class="small-box-footer"><span class="a-link-card">más información </span>  <i class="i-icon-card fas fa-arrow-circle-right"></i></a>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>

</section>