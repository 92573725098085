export class notificacionMdl {

    nuevos:string;
    enviados:string;
    pendientes:string;
    urgentes:string;
   

        constructor(){

          
            this.nuevos='';
            this.enviados = '';
            this.pendientes='';
            this.urgentes='';

        }
}