export class BucketSalidaMdl {

    bucketSalidaId:number;
    ctrlCreado:Date;
    ctrlCreadoPor:number;
    ctrlActualizado:Date;
    ctrlActualizadoPor:number;
    ctrlActivo:boolean;
    folioSalida:string;
    fechaSalida:Date;
    grupoOrganizacionalId:any;
    documentoId:any;
    documentoFisicoId:any;
    organizacionId:any;


            constructor(){

                this.bucketSalidaId= null;
                this.ctrlCreado = null;
                this.ctrlCreadoPor = null;
                this.ctrlCreado = null;
                this.ctrlCreadoPor = null;
                this.ctrlActivo = true;
                this.folioSalida = null;
                this.fechaSalida = null;
                this.grupoOrganizacionalId = '';
                this.documentoId = '';
                this.documentoFisicoId = '';
                this.organizacionId = '';

            }
}