import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenApiService } from 'src/app/_utils/services/open-api.service';
import { DocumentoFisicoService } from 'src/app/_utils/services/Service-Entidades/documentoFisico.service';
import { DocumentoFisicoCustom } from 'src/app/_modelos/documento';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { AdjuntoDialogComponent } from '../adjunto-dialog/adjunto-dialog.component';
import { AcuseDialogComponent } from '../acuse-dialog/acuse-dialog.component';
import { DinamicoService } from 'src/app/_utils/services/dinamico.service';
import { yearsData } from 'src/app/_utils/interface/interfaz';

@Component({
  selector: 'app-documento-fisico-listado',
  templateUrl: './documento-fisico-listado.component.html',
  styleUrls: ['./documento-fisico-listado.component.scss']
})
export class DocumentoFisicoListadoComponent implements OnInit {

  cols: any[] = [];
  dato: any[] = [];
  data: DocumentoFisicoCustom[] = [];
  Ancho = "100%";
  clase: any = [];
  id = "";
  NombreClase: any;
  NombreClaseSplit: any;
  bucketEntradaId: number;

  endpointObject: any;
  endpoint: any;
  loading =false;

  /* array list that containt the years*/
  selectedYear:any;
  YearList:yearsData[] = [];

  constructor(private route: ActivatedRoute,
    private documentoService: DocumentoFisicoService,
    private router: Router,
    public apiService: OpenApiService,
    public dialog: MatDialog,
    public dinamicoService: DinamicoService, 
  ) { }

  ngOnInit(): void {
    this.endpoint = this.apiService.BuscarEndpointPorClase("#/components/schemas/DocumentoFisico");

    this.clase = this.apiService.BuscarEndpointClase(this.endpoint);
    this.cols = this.apiService.ListaCamposClase(this.clase);
    this.Ancho = (this.cols.length) * 6 + "%";

    this.cols = [{ field: "Acciones", header: "Acciones" },
    { field: "numeroDocumentoOriginal", header: "Oficio Original" },
    { field: "asunto", header: "Asunto" },
    { field: "puesto", header: "Puesto Remitente/Destinatario" },
    { field: "cadenaFolio", header: "Folio Interno" },
    { field: "ctrlCreado", header: "Fecha Creación" },
    { field: "entradaSalida", header: "Tipo Correspondencia" },
    { field: "tipoDocumento", header: "Tipo Documento" },
    { field: "prioridad", header: "Prioridad" },
      //  { field: "tipoPrivacidadId", header: "Privacidad" }
    ]
   
    /**the paint the list when load*/
    this.YearList = this.dinamicoService.years();
    let year = this.YearList.map( (item) => item.value);//recorre the array list year
    let actualyear = {year:year[0]}//json that have the first array list
    this.find( 50, 1, actualyear)//The call function for paint list data
  }

  find( registros:number , pagina:number, actualyear:any){
    this.loading =true;
    // debugger
    let numeroFinal= (registros * pagina) 
    let numeroInicio = numeroFinal - registros

    // debugger

    //this.documentoService.DocumentoFindallRange(numeroInicio, numeroFinal - 1)  //paginador comentado
    //this.documentoService.DocumentoFindall() 
    this.documentoService.DocumentoDTOFindallYear(actualyear).subscribe(
      data => {
        //console.log(data);
        this.data = data;
        // this.data = this.data.filter(a => a.ctrlActivo == true)

        this.dato = this.data;
        this.dato.forEach(element => {

          // try {
          //   element.organizacionDestinoId = element.organizacionDestinoId.organizacionId
          // } catch {
          //   element.organizacionDestinoId = " ";
          // }

          /*try {
            element.remitenteDestinatarioId = element.remitenteDestinatarioId.puesto
          } catch {
            element.remitenteDestinatarioId = " ";
          }*/

          try {
            element.puesto = element.remitenteDestinatarioId.puesto
          } catch {
            element.puesto = " ";
          }

          try {
            element.prioridad = element.prioridadDTOId.nombre
          } catch {
            element.prioridad = "";
          }

          try {
            element.tipoDocumento = element.tipoDocumentoDTOId.nombre
          } catch {
            element.tipoDocumento = "";
          }

          try {
            element.ctrlCreado = new Date(element.ctrlCreado).toLocaleDateString();
          } catch {
            element.ctrlCreado = "";
          }

        });

        this.loading =false;

      },
      error => {
        this.loading =false;
        console.log(error);
      }

    );
  }

  TipoDatoValor(valor, campo) {
    var valorForm: any = [];
    var tipo: any = [];

    try {
      tipo = this.clase.properties[campo].$ref
      valorForm = valor[campo];
    }
    catch {

    }

    if (valorForm == undefined) {
      try {

        tipo = this.clase.properties[campo].type;
        if (tipo == "boolean") {

          if (valor == true) {
            valorForm = "Verdadero";
          }
          else {
            valorForm = "Falso";
          }
        }

        else {
          valorForm = valor;
        }
      } catch (error) {
      }
    }
    return valorForm;
  }

  TipoDatoClase(campo) {

    var valorForm: any = [];
    var tipo: any = [];

    try {
      tipo = this.clase.properties[campo].$ref
      valorForm = "Object";
    }
    catch {

    }

    if (tipo == undefined) {
      try {

        valorForm = this.clase.properties[campo].type;

        if (valorForm == "string") {

          if (this.clase.properties[campo].format == "date-time") {
            valorForm = "Date";

          }
        }

      } catch (error) {

      }
    }
    return valorForm;
  }

  EditarClase(row) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/documentofisico/formulario/' + row.documentoFisicoId]));
  }

  borrar(act: any, i: number) {

    Swal.fire({
      title: 'Eliminar',
      text: `Eliminará  ${act.documentoFisicoId}`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then(resp => {
      if (resp.value) {

        this.documentoService.DocumentoRemove(act.documentoFisicoId)
          .subscribe(resp => {
            this.ngOnInit();

          })


      }
    })
  }

  visorAdjuntos(data) {
    // debugger
    let tipoDoc: String = "";
    tipoDoc = "documentoFisico";


    var dialogRef = this.dialog.open(AdjuntoDialogComponent, {
      width: '50%',
      height: '90%',
      data: { id: data.documentoFisicoId, tipoDoc: tipoDoc , modal: this.dialog},
    });


    

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }

  visorAcuses(data) {
    // debugger
    let tipoDoc: String = "";
    tipoDoc = "documentoFisico";


    var dialogRef = this.dialog.open(AcuseDialogComponent, {
      width: '50%',
      height: '90%',
      data: { id: data.documentoFisicoId, tipoDoc: tipoDoc , modal: this.dialog},
    });
 
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }

  changeYear = (event:any) => {
    let yearChange = {year:event.value};
    this.data.length = 0;//the reset the array
    this.dato.length = 0//the reset the array
    this.find( 50, 1, yearChange);//the call function for paint the data when change select
    this.selectedYear = event.value;
  }

  turnar = (event:any) => {
    let doc:number = event.documentoFisicoId
    this.documentoService.getBucketEntradaByDocumentoFisico(doc).subscribe(data => { 
      if (data[0]) {
       this.bucketEntradaId = data[0].bucketEntradaId;
       this.router.navigate(['bucket/formulario',this.bucketEntradaId]);
      }
    });
  }

}
