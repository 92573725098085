import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators, FormBuilder, Form } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GrupoEnvioService } from 'src/app/_utils/services/Service-Entidades/GrupoEnvio.service';
import { OpenApiService } from 'src/app/_utils/services/open-api.service';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { TratamientoMdl } from 'src/app/_modelos/Tratamiento';
import { DinamicoService } from 'src/app/_utils/services/dinamico.service';
import { TratamientoService } from 'src/app/_utils/services/Service-Entidades/tratamiento.service';


@Component({
    selector: 'app-tratamiento',
    templateUrl: './tratamiento.component.html',
    styleUrls: ['./tratamiento.component.scss']
})


export class TratamientoComponent implements OnInit {

    TR: TratamientoMdl = new TratamientoMdl();

    Tra: TratamientoMdl = new TratamientoMdl()
    trs: TratamientoMdl[] = []
    GOss: number = 0;

    traa: TratamientoMdl = new TratamientoMdl()
    TRAS: TratamientoMdl[] = []
    TRASS: number = 0;

    grupo: any = null;
    usuario: any;

    formGroup: FormGroup;

    get f() { return this.formGroup.controls; }
    isSave: boolean = true

    constructor(
        private _formbuilder: FormBuilder,
        private route: ActivatedRoute,
        private Route: Router,
        private dinamicoService: DinamicoService,
        private TRService: TratamientoService,
        public apiService: OpenApiService,) {

        this.formGroup = this._formbuilder.group(this.TR);

    }

    ngOnInit() {

        debugger

        this.grupo = this.apiService.getUsuarioToken();
        this.usuario = this.apiService.getCurrentUser();

      

            debugger

        const id = this.route.snapshot.paramMap.get('id')
        if (id !== 'new') {
            this.isSave = false

            this.TRService.TratamientoFind(Number(id)).subscribe((resp: TratamientoMdl) => {

                debugger
                this.TR = resp

                /*var grupoenvio: GrupoEnvioMdl = new GrupoEnvioMdl();
                grupoenvio.grupoOrganizacionalId = this.usuario.grupo_organizacional_remitente_id
                grupoenvio.organizacionId = this.usuario.organizacion_remitente_id*/

                this.formGroup = this._formbuilder.group(this.TR);

            })



        }

    }


    guardarTR() {

        debugger

        if (this.formGroup.invalid) {
            //Aquí va la validación del form
            console.log(this.formGroup)
            console.log('Form no valido')
            return
        }
        let peticion: Observable<any>
        Swal.fire({
            title: 'Espere',
            text: 'Guardando información',
            icon: 'info',
            allowOutsideClick: false
        })

        debugger

        this.TR = this.formGroup.value;
        this.TR.ctrlActivo = true;
        this.TR.ctrlCreadoPor = 1;
        this.TR.ctrlActualizado = new Date();
        this.TR.ctrlActualizadoPor = 1;
       
        Swal.showLoading()

        if (this.TR.tratamientoId) {
            this.TR.ctrlCreado = this.TR.ctrlCreado
            debugger
            peticion = this.TRService.TratamientoEdit(this.TR.tratamientoId, this.TR)

            console.log(this.TR)
        }

        else {
            this.TR.ctrlCreado = new Date()
            console.log(this.TR)
            debugger
            peticion = this.TRService.TratamientoCreate(this.TR)
        }

        peticion.subscribe(resp => {
            Swal.fire({
                title: this.TR.nombre,
                text: 'Realizado correctamente',
                icon: 'success',
            })
        },

            error => {
                if (error) {

                }

                Swal.fire({
                    title: this.TR.nombre,
                    text: 'error',
                    icon: 'error',
                })
            },

        )


    }

}

