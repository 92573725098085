<section class="content-header header-siadci">
    <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <h1>Relacionar documento
            </h1>
          </div>
          <div class="col-sm-6">
            <h3>Buscar el número de documento original</h3>
          </div>
        </div>
      </div>
</section>

<section class="content content-sidci">
    <div class="container-flud">
        <div class="card">
            <div class="card-body">
                <div class="container">
                    <div class="row justify-content-md-center">
                        <div class="col-lg-6">
                            <div class="input-group">
                                <input type="search" id="inputBuscar" class="form-control rounded input-search" placeholder="Buscar" aria-label="Search" aria-describedby="search-addon" /> 
                                <button type="button" (click)="buscarDocumento()" class="btn btn-search"><i class="fas fa-search"></i> Buscar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="error == 1" class="container text-center" style="margin-top: 12px;">
                    <h3><span class="bg-danger rounded">No se encontró el documento</span></h3>
                </div>
                <br>
                <div class="container">
                    <table class="table table-bordered">
                    <thead>
                        <tr>
                        <th scope="col">Relacionar</th>
                        <th scope="col">Folio</th>
                        <th scope="col">Fecha recepcion</th>
                        <th scope="col">Asunto</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor = "let doc of documentos;let indice = index">
                        <!--<th scope="row">{{indice+1}}</th>-->
                        <td>
                            <button (click)="buscarDocumentoRelacionado(doc.documento_fisico_id,$event)" id="btn{{indice+1}}" class="btn btn-search">
                            <span *ngIf="spinner == 0" class="badge">Aceptar</span>
                            <div *ngIf="spinner == 1" class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden"></span>
                            </div>
                            </button>
                        </td>
                        <td>{{doc.cadena_folio}}</td>   
                        <td>{{doc.fecha_recepcion}}</td>
                        <td>{{doc.asunto}}</td>
                        </tr>
                    </tbody>
                    </table>

                </div>
            </div>
            
        </div>
    </div>
</section>