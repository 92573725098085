import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators, FormBuilder, Form } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenApiService } from 'src/app/_utils/services/open-api.service';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { LeyendaMdl } from 'src/app/_modelos/Leyenda';
import { LeyendaService } from 'src/app/_utils/services/Service-Entidades/Leyenda';
import { DinamicoService } from 'src/app/_utils/services/dinamico.service';


@Component({
    selector: 'app-leyenda',
    templateUrl: './leyenda.component.html',
    styleUrls: ['./leyenda.component.scss']
})


export class leyendaComponent implements OnInit {

    LY: LeyendaMdl = new LeyendaMdl();

    L: LeyendaMdl = new LeyendaMdl()
    E: LeyendaMdl[] = []
    y: number = 0;

    LEY: LeyendaMdl = new LeyendaMdl()
    con: LeyendaMdl[] = []
    tcon: number = 0;

    grupo: any = null;
    usuario: any;

    formGroup: FormGroup;

    get f() { return this.formGroup.controls; }
    isSave: boolean = true

    constructor(
        private _formbuilder: FormBuilder,
        private route: ActivatedRoute,
        private Route: Router,
        private dinamicoService: DinamicoService,
        private lyService: LeyendaService,
        public apiService: OpenApiService,) {

        this.formGroup = this._formbuilder.group(this.LY);

    }

    ngOnInit() {

        debugger

        this.grupo = this.apiService.getUsuarioToken();
        this.usuario = this.apiService.getCurrentUser();

      

            debugger

        const id = this.route.snapshot.paramMap.get('id')
        if (id !== 'new') {
            this.isSave = false

            this.lyService.LeyendaFind(Number(id)).subscribe((resp: LeyendaMdl) => {

                debugger
                this.LY = resp

                /*var grupoenvio: GrupoEnvioMdl = new GrupoEnvioMdl();
                grupoenvio.grupoOrganizacionalId = this.usuario.grupo_organizacional_remitente_id
                grupoenvio.organizacionId = this.usuario.organizacion_remitente_id*/

                this.formGroup = this._formbuilder.group(this.LY);

            })



        }

    }


    guardarLEY() {

        debugger

        if (this.formGroup.invalid) {
            //Aquí va la validación del form
            console.log(this.formGroup)
            console.log('Form no valido')
            return
        }
        let peticion: Observable<any>
        Swal.fire({
            title: 'Espere',
            text: 'Guardando información',
            icon: 'info',
            allowOutsideClick: false
        })

        debugger

        this.LY = this.formGroup.value;
        this.LY.ctrlActivo = true;
        this.LY.ctrlCreadoPor = 1;
        this.LY.ctrlActualizado = new Date();
        this.LY.ctrlActualizadoPor = 1;
       
        Swal.showLoading()

        if (this.LY.leyendaCcpId) {
            this.LY.ctrlCreado = this.LY.ctrlCreado
            debugger
            peticion = this.lyService.LeyendaEdit(this.LY.leyendaCcpId, this.LY)

            console.log(this.LY)
        }

        else {
            this.LY.ctrlCreado = new Date()
            console.log(this.LY)
            debugger
            peticion = this.lyService.LeyendaCreate(this.LY)
        }

        peticion.subscribe(resp => {
            Swal.fire({
                title: this.LY.nombre,
                text: 'Realizado correctamente',
                icon: 'success',
            })
        },

            error => {
                if (error) {

                }

                Swal.fire({
                    title: this.LY.nombre,
                    text: 'error',
                    icon: 'error',
                })
            },

        )


    }

}

