
export class MenuSideNav {

    titulo: string
    data: any=[]
    icono: string
    url: string
    activo: boolean=true;
    submenu: any[]

}