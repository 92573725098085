import {Injectable, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class TratamientoService{
   constructor(private http: HttpClient,){}

   TratamientoFindall() {
    return this.http.get<any[]>(environment.apiURL + '/webresources/tratamiento');
}

GetTC() {
    return this.http.get<any[]>(environment.apiURL + '/webresources/tipocontacto');
 }

 GetTratamiento() {
    return this.http.get<any[]>(environment.apiURL + '/webresources/tratamiento');
 }

TratamientoCreate(entidad: any) {
    return this.http.post(environment.apiURL + '/webresources/tratamiento', entidad);
}

TratamientoFind(id: number) {
    return this.http.get<any>(environment.apiURL + '/webresources/tratamiento/' + id);
}

TratamientoEdit(id: number, entidad: any) {
    return this.http.put(environment.apiURL + '/webresources/tratamiento/' + id, entidad);
}

TratamientoRemove(id: number) {
    return this.http.delete(environment.apiURL + '/webresources/tratamiento/' + id);
}


}