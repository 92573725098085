import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { TrazaInternaService } from 'src/app/_utils/services/Service-Entidades/TrazaInternaService';

@Component({
  selector: 'app-turnos-tree-listado',
  templateUrl: './turnos-tree-listado.component.html',
  styleUrls: ['./turnos-tree-listado.component.scss']
})
export class TurnosTreeListadoComponent implements OnInit {
  /****** */
  totalRecords: number = 0;
  lazyloadTurnos: any[] = [];
  /****** */
  ListTurnos: any[] = [];
  seguimientoTurnos: any[] = [];
  data: any[] = [];
  data1: any[] = [];
  loading: boolean;

  cols: any[] = [
    //{ field: "trazaInternaId", header: "Turno" },
    { field: "Visualizar", header: "Visualizar", width: "10%" },
    { field: "Acciones", header: "Acciones", width: "10%" },
    { field: "estatus", header: "Estatus del Turno" },
    { field: "OrigenNombre", header: "Área que Turna" },
    { field: "DestinoNombre", header: "Área Destino" },
    { field: "numeroDocumentoOriginal", header: "No. oficio original" },
    { field: "documentoAsunto", header: "Asunto Oficio" },
    { field: "cadenaFolio", header: "Folio Interno" },
    { field: "prioridad", header: "Prioridad" },
    { field: "fechaTurno", header: "Fecha del Turno" },
    { field: "agrupador", header: "Volante de Referencia" },
  ];

  colsSeguimiento: any[] = [
    { field: "estatus", header: "Estatus del Turno" },
    { field: "OrigenNombre", header: "Área que Turna" },
    { field: "DestinoNombre", header: "Área Destino" },
    { field: "numeroDocumentoOriginal", header: "No. oficio original" },
    { field: "documentoAsunto", header: "Asunto Oficio" },
    { field: "cadenaFolio", header: "Folio Interno" },
    { field: "prioridad", header: "Prioridad" },
    { field: "fechaTurno", header: "Fecha del Turno" },
    { field: "agrupador", header: "Volante de Referencia" },
  ];

  constructor(
    private TIservice: TrazaInternaService,
  ) { }

  ngOnInit(): void {
     this.loading = true;
    const actualyear = {year: "2023"}
    this.TIservice.TrazaInternaFindallrecibidosYear(actualyear).subscribe(
      data => {
        console.log(data);
        this.ListTurnos = data;
        console.log(data.length);
        this.totalRecords = data.length;
        this.ListTurnos.forEach((element:any) => {
          try {
            if (element.estatusTurnoId.nombre == "Turnado") {
              element.estatus = "Recibido";
            } else {
              element.estatus = element.estatusTurnoId.nombre;
            }
          } catch {
            element.estatus = "";
          }
          try {
            //Si organizacionorigen es nulo, toma campo grupo organizacional origen
            if (element.organizacionIdOrigen == null) {
              element.OrigenNombre = element.grupoOrganizacionalIdOrigen.nombre;
            } else {
              // Toma organizacionOrigen
              element.OrigenNombre = element.organizacionIdOrigen.nombre;
            }
          } catch {
            element.OrigenNombre = "";
          }
          try {
            //Si organizaciondestino es nulo, toma campo grupo organizacional destino
            if (element.organizacionIdDestino == null) {
              element.DestinoNombre = element.grupoOrganizacionalIdDestino.nombre;
              element.organizacionIdDestino = "";
            } else {
              // Toma organizacionOrigen
              element.DestinoNombre = element.organizacionIdDestino.nombre;
            }
          } catch {
            element.DestinoNombre = "";
            element.organizacionIdDestino = "";
            element.organizacionIdDestino.organizacionId = "";
          }
          try {
            element.numeroDocumentoOriginal = element.documentoFisicoId.numeroDocumentoOriginal;
          } catch {
            element.numeroDocumentoOriginal = "";
          }
          try {
            element.documentoAsunto = element.documentoFisicoId.asunto;
          } catch {
            element.documentoAsunto = "";
          }
          try {
            element.cadenaFolio = element.documentoFisicoId.cadenaFolio;
          } catch {
            element.cadenaFolio = "";
          }
          try {
            element.prioridad = element.documentoFisicoId.prioridadId.nombre
          } catch {
            element.prioridad = "";
          }
          try {
            element.fechaTurno = new Date(element.ctrlCreado).toLocaleDateString();
          } catch {
            element.fechaTurno = "";
          }
          try {
            element.agrupador = element.agrupador + "/" + "2021";
            //element.agrupador = element.agrupador;
          } catch {
            element.agrupador = "";
          }
        });
        console.log(this.ListTurnos);
        this.ListTurnos = this.ListTurnos.map((data:any) => {
          return {
            ...data,
            expanded: false,
          }
        })
        console.log(this.ListTurnos);
        this.loading = false;
      },
      error => {
        console.log(error)
      }
    );

  }

  loadTurnos = (event: LazyLoadEvent) => {
     this.loading = true;
    console.log(event)
    //setTimeout(() => {
      console.log(this.ListTurnos)
      if (this.ListTurnos) {
        console.log(this.ListTurnos)
        this.lazyloadTurnos = this.ListTurnos.slice(event.first, (event.first + event.rows));
        this.loading = false;
      }
    //}, 1000);
  };

  rowExpanded = (rowData:any) => {
    console.log(rowData);
    //console.log(rowData.documentoFisicoId.documentoFisicoId);
    //console.log(rowData.organizacionIdDestino.organizacionId);
    rowData.expanded = !rowData.expanded;    
    if (rowData.expanded) {
      const documentoFisicoId = rowData.documentoFisicoId.documentoFisicoId;
      const organizacionDestinoId = rowData.organizacionIdDestino.organizacionId;
      const data = {
        documentofisicoid: documentoFisicoId,
        organizaciondestinoid :organizacionDestinoId,
      }    
      console.log(data);
      console.log(JSON.stringify(data));
      console.log(rowData.expanded);
      this.TIservice.TrazaInternaFindAllSeguimientoRecibidos(data).subscribe({
        next: (value) => {
          console.log(value);
          this.seguimientoTurnos = value;
          this.seguimientoTurnos.forEach((element) => {
            try {
              if (element.estatusTurnoId.nombre == "Turnado") {
                element.estatus = "Recibido";
              } else {
                element.estatus = element.estatusTurnoId.nombre;
              }
            } catch {
              element.estatus = "";
            }
            try {
              //Si organizacionorigen es nulo, toma campo grupo organizacional origen
              if (element.organizacionIdOrigen == null) {
                element.OrigenNombre = element.grupoOrganizacionalIdOrigen.nombre;
              } else {
                // Toma organizacionOrigen
                element.OrigenNombre = element.organizacionIdOrigen.nombre;
              }
            } catch {
              element.OrigenNombre = "";
            }
            try {
              //Si organizaciondestino es nulo, toma campo grupo organizacional destino
              if (element.organizacionIdDestino == null) {
                element.DestinoNombre = element.grupoOrganizacionalIdDestino.nombre;
                element.organizacionIdDestino = "";
              } else {
                // Toma organizacionOrigen
                element.DestinoNombre = element.organizacionIdDestino.nombre;
              }
            } catch {
              element.DestinoNombre = "";
              element.organizacionIdDestino = "";
              element.organizacionIdDestino.organizacionId = "";
            }
            try {
              element.numeroDocumentoOriginal = element.documentoFisicoId.numeroDocumentoOriginal;
            } catch {
              element.numeroDocumentoOriginal = "";
            }
            try {
              element.documentoAsunto = element.documentoFisicoId.asunto;
            } catch {
              element.documentoAsunto = "";
            }
            try {
              element.cadenaFolio = element.documentoFisicoId.cadenaFolio;
            } catch {
              element.cadenaFolio = "";
            }
            try {
              element.prioridad = element.documentoFisicoId.prioridadId.nombre
            } catch {
              element.prioridad = "";
            }
            try {
              element.fechaTurno = new Date(element.ctrlCreado).toLocaleDateString();
            } catch {
              element.fechaTurno = "";
            }
            try {
              element.agrupador = element.agrupador + "/" + "2021";
              //element.agrupador = element.agrupador;
            } catch {
              element.agrupador = "";
            }
          });
          console.log(this.seguimientoTurnos);
        },  
        error: (err) => {console.log(err); }     
      });
    }
  }
}
