import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import { OpenApiService } from 'src/app/_utils/services/open-api.service';
import { Observable } from 'rxjs';
import { TratamientoMdl } from 'src/app/_modelos/Tratamiento';
import { TratamientoService } from 'src/app/_utils/services/Service-Entidades/tratamiento.service';
import { Router } from '@angular/router';

@Component ({
    selector:'app-listado-tratamiento',
    templateUrl:'./listado-tratamiento.component.html',
    styleUrls:['./listado-tratamiento.component.scss']
})


export class ListadoTratamientoComponent implements OnInit {

    tra: TratamientoMdl = new TratamientoMdl();
    data:any[] = []
    tras: TratamientoMdl[]=[]
    Ancho = "100%";
    p:number = 1
    usuario: any; 


    cols:any[]=[

        {field:"tratamientoId", header:"Tratamiento"},
        {field:"nombre", header:"Nombre"},
        {field:"descripcion", header:"Descripción"},
        {field:"Acciones", header:"Acciones"},

    ];

    isSave: boolean = true

    constructor(private TRservice: TratamientoService,
     public apiService: OpenApiService,
      private router: Router){ }


      ngOnInit(){
        this.usuario = this.apiService.getCurrentUser();
        this.TRservice.TratamientoFindall()
        .subscribe(resp => {
            this.tras = resp
            this.tras = this.tras.filter(a => a.ctrlActivo == true)
            this.data = this.tras;     

            this.data.forEach(element => {
                
                try {
                    element.nombre = element.nombre
                  } catch{
                    element.nombre = "";
                  }

                  try {
                    element.descripcion = element.descripcion
                  } catch{
                    element.descripcion = "";
                  }

                  try {
                    element.organizacionnombre = element.organizacionId.nombre
                  } catch{
                    element.organizacionnombre = "";
                  }

                  try {
                    element.grupoorganizacionalnombre = element.grupoOrganizacionalId.nombre
                  } catch{
                    element.grupoorganizacionalnombre = "";
                  }


            })

        })
    }

    borrarTratamiento(trata: TratamientoMdl,  i:number){
        Swal.fire({
          title:'Eliminar',
          text:`Eliminará  ${trata.tratamientoId}`,
          icon: 'question',
          showConfirmButton:true,
          showCancelButton:true
        }).then(resp => {
          if(resp.value){
            //this.tra.splice(i, 1)
            this.tras = this.tras.filter( b => b.tratamientoId !== trata.tratamientoId)
            this.data = this.tras.filter(b => b.tratamientoId !== trata.tratamientoId)
            trata.ctrlActivo = false;
            console.log(trata)
            this.TRservice.TratamientoRemove(
              trata.tratamientoId,
             // trata
              ).subscribe()
          }
        })
      }



}