<section class="content-header header-siadci">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <h1>Observaciones</h1>
      </div>
      <div class="col-sm-6">
        <h3>Observaciones</h3>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<section class="content content-sidci">
  <div class="container-fluid">
    <div class="siadci-content-wrapper">
      <form [formGroup]="formGroup">
        <div class="box-body">
          <div class="row mx-4 py-3">
            <div class="section-siadci py-3 col-12">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Observaciones Cierre</mat-label>
                <textarea
                  matInput
                  type="text"
                  placeholder="Observaciones Cierre"
                  formControlName="observacionesCierre"
                  [(value)]="doc.observacionesCierre"
                  
                  required></textarea>

                <mat-error *ngIf="f.observacionesCierre.hasError('required')">
                  <strong>Este valor </strong> es requerido.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="box-footer-siadci">
          
          <button
            type="button"
            class="btn-primary-siadci"
            (click)="CerrarDocumentoBE();">
            <i class="fa fa-edit"></i> Guardar cambios
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
