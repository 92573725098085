import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators, FormBuilder, Form } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TrazaInternaService } from 'src/app/_utils/services/Service-Entidades/TrazaInternaService';
import { OpenApiService } from 'src/app/_utils/services/open-api.service';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { TrazaInternaMdl } from 'src/app/_modelos/Traza_interna';
import { DocumentoMdl } from 'src/app/_modelos/documento';
import { EstatusTurnoMdl } from 'src/app/_modelos/EstatusTurno';
import { GrupoOrganizacionalMdl } from 'src/app/_modelos/grupoOrganizacional';
import { OrganizacionMdl } from 'src/app/_modelos/organizacion';
import { BucketEntradaMdl } from 'src/app/_modelos/bucketentrada1';
import { DinamicoService } from 'src/app/_utils/services/dinamico.service';




@Component({
  selector: 'app-turno',
  templateUrl: './Turno-EnAtencion.component.html',
  styleUrls: ['./Turno-EnAtencion.component.scss']
})


export class TurnoEnAtencionComponent implements OnInit {



  TI: TrazaInternaMdl = new TrazaInternaMdl()

  documento: DocumentoMdl = new DocumentoMdl()
  documentos: DocumentoMdl[] = []
  documentoss: number = 0;

  bucket: BucketEntradaMdl = new BucketEntradaMdl()
  buckets: BucketEntradaMdl[] = []
  bucketss: number = 0;

  ET: EstatusTurnoMdl = new EstatusTurnoMdl()
  ETs: EstatusTurnoMdl[] = []
  ETss: number = 0;

  GO: GrupoOrganizacionalMdl = new GrupoOrganizacionalMdl()
  GOs: GrupoOrganizacionalMdl[] = []
  GOss: number = 0;

  organizacion: OrganizacionMdl = new OrganizacionMdl()
  organizacions: OrganizacionMdl[] = []
  organizacionss: number = 0;

  ListadoContacto: any[] = [];
  grupo: any = null;


  ListadoOrganizacion: any[] = [];


  formGroup: FormGroup;


  get f() { return this.formGroup.controls; }
  isSave: boolean = true

  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dinamicoService: DinamicoService,
    private TIservice: TrazaInternaService,
    public apiService: OpenApiService,) {

    this.formGroup = this._formbuilder.group(this.TI);
    //this.formGroup.controls.grupoOrganizacionalIdOrigen.setValue(this.TI.grupoOrganizacionalIdOrigen);
    //this.formGroup.controls.organizacionIdOrigen.setValue(this.TI.organizacionIdOrigen);

  }

  ngOnInit() {

    debugger
    this.grupo = this.apiService.getUsuarioToken();

    const id = this.route.snapshot.paramMap.get('id')
    if (id !== 'new') {
      this.isSave = false
      //Editar

      this.TIservice.TrazaInternaFind(Number(id)).subscribe((resp: TrazaInternaMdl) => {

        let endpointActor = this.apiService.BuscarEndpointPorClase("#/components/schemas/Actor");

        this.dinamicoService.getIndividual(endpointActor.ruta, 1).subscribe(resp => {
          this.organizacion = resp;
        });

        //  this.dinamicoService.getListado("/webresources/organizacion/list/"+ this.grupo.grupo_organizacional_remitente_id ).subscribe(resp => {
        //Nuevo endpoint, sólo para turnar hacia GO - ORG
        this.dinamicoService.getListado("/webresources/destinatarios/turnar").subscribe(resp => {

          this.ListadoOrganizacion = resp;
          resp.forEach(element => {
            element.NombreCompleto = element.puesto_destinatario + " - " + element.nombre_destinatario;
          })
          this.ListadoContacto = resp;
        });

        var trazaInterna: TrazaInternaMdl = new TrazaInternaMdl();

        trazaInterna.documentoFisicoId = resp.documentoFisicoId;

          debugger
        /* Valida si GO origen y destino son diferentes (Para identificar si el turno proviene de GO hacia otro GO, o es dentro del mismo GO)
         */
        //Si origen y destino son iguales
        if (resp.grupoOrganizacionalIdOrigen.grupoOrganizacionalId == resp.grupoOrganizacionalIdDestino.grupoOrganizacionalId) {
          //Asigna GO origen a origen
          trazaInterna.grupoOrganizacionalIdOrigen = resp.grupoOrganizacionalIdOrigen;
          this.formGroup.controls.grupoOrganizacionalIdOrigen.setValue(trazaInterna.grupoOrganizacionalIdOrigen);

        }
        else {
          //Asigna GO destino al origen
          trazaInterna.grupoOrganizacionalIdOrigen = resp.grupoOrganizacionalIdDestino;
          this.formGroup.controls.grupoOrganizacionalIdOrigen.setValue(trazaInterna.grupoOrganizacionalIdOrigen);

        }

        //Obtiene ORG origen
        trazaInterna.organizacionIdOrigen = resp.organizacionIdOrigen
        this.formGroup.controls.organizacionIdOrigen.setValue(trazaInterna.organizacionIdOrigen);
        trazaInterna.mensaje = "";
        //trazaInterna.instrucciones = "";


        this.formGroup = this._formbuilder.group(trazaInterna);

      })
    }
  }

  CambRespEA():void {

    debugger
    const id = this.route.snapshot.paramMap.get('id')
    if (id !== 'new') {
      this.isSave = false
      debugger
      //Editar
  
  
        this.TIservice.TrazaInternaFind(Number(id)).subscribe((resp: TrazaInternaMdl) => {
  
          debugger
          resp.estatusTurnoId = 4;
  
          
  
          let peticion: Observable<any>
          Swal.fire({
            title: 'Espere',
            text: 'Guardando información',
            icon: 'info',
            allowOutsideClick: false
          })
  
          debugger
          peticion = this.TIservice.AutorizarEstatus(resp.trazaInternaId, resp)
         
  
          peticion.subscribe(resp => {
  
  
            this.ngOnInit();
  
            Swal.fire({
              title: id,
              text: 'Realizado correctamente',
              icon: 'success',
            })
          },
  
            error => {
              if (error) {
  
              }
  
              Swal.fire({
                title:id,
                text: 'error',
                icon: 'error',
              })
            },
  
          )

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/turnos/listaEA']);
                      
            });
  
          })
        
  
      
      }
    }

  guardarTurnoEA() {

    debugger

    if (this.formGroup.invalid) {
      let temp: string[] = [];
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      icon: 'info',
      allowOutsideClick: false
    })

    debugger
    let temp: TrazaInternaMdl = this.formGroup.value;
    this.grupo = this.apiService.getUsuarioToken();

  

    //Obtengo el info de origen desde el token
    //temp.grupoOrganizacionalIdOrigen = this.grupo.grupo_organizacional_remitente_id;
    temp.organizacionIdOrigen = this.grupo.organizacion_remitente_id;
    temp.trazaInternaId = null;


    temp.ctrlActivo = true;
    temp.ctrlCreadoPor = 1;
    temp.ctrlActualizado = new Date();
    temp.ctrlActualizadoPor = 1;
    temp.estatusTurnoId = 1;
    temp.agrupador = 1
    temp.documentoId = null;
    temp.ctrlCreado = new Date();



    // temp.organizacionIdDestino = Number(this.organizacion.organizacionId)

    // var test:OrganizacionMdl=temp.organizacionIdDestino;
    //  temp.organizacionIdDestino=test;
    ////// temp.organizacionIdDestino=temp.organizacionIdDestino.organizacionId;
    //temp.organizacionIdDestino=this.ListadoOrganizacion.find(element=>element.organizacionId=temp.organizacionIdDestino.organizacionId)
    //var temp2=this.ListadoOrganizacion.find(element=>element.organizacionId=temp.organizacionIdDestino.organizacionId)


    /* Validaciones para identificar si el destinarario es GO - ORG  */

    // Si trae no datos organización_destino, asigna los valores seleccionados a GO destino y nullea organización_destino
    if (temp.organizacionIdDestino.organizacion_destinatario_id == null) {
      temp.grupoOrganizacionalIdDestino = temp.organizacionIdDestino;
      temp.organizacionIdDestino = null;
    }
    else {//Si no, es una organización y obtiene el ID
      temp.organizacionIdDestino = temp.organizacionIdDestino.organizacion_destinatario_id;
    }

    //Valida si viene nulo el GO destino, toma el GO origen y lo asigna a destino
    if (temp.grupoOrganizacionalIdDestino.grupo_organizacional_destinatario_id == null) {
      temp.grupoOrganizacionalIdDestino = temp.grupoOrganizacionalIdOrigen.grupoOrganizacionalId
    }
    else {
      temp.grupoOrganizacionalIdDestino = temp.grupoOrganizacionalIdDestino.grupo_organizacional_destinatario_id

    }

    Swal.showLoading()

    debugger
    this.TI.ctrlCreado = new Date()
    console.log(this.TI)
    peticion = this.TIservice.TrazaInternaCreate(temp)


    peticion.subscribe(resp => {
      Swal.fire({
        title: this.TI.trazaInternaId,
        text: 'Realizado correctamente',
        icon: 'success',
      })
      this.CambRespEA();
    },

      error => {
        if (error) {

        }

        Swal.fire({
          title: this.TI.trazaInternaId,
          text: 'error',
          icon: 'error',
        })
      },

    )
    

  }


}
