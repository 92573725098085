import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit,Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Console } from 'console';
import {BucketSalidaService} from 'src/app/_utils/services/Service-Entidades/BucketSalida.service';
import {DocumentoFisicoService} from 'src/app/_utils/services/Service-Entidades/documentoFisico.service';
import {BucketSalidaMdl} from 'src/app/_modelos/bucketsalida1';
import { FormGroup,FormBuilder } from '@angular/forms';
import { DocumentoFisicoMdl } from 'src/app/_modelos/documento';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-relacionar-documento',
  templateUrl: './modal-relacionar-documento.component.html',
  styleUrls: ['./modal-relacionar-documento.component.scss']
})
export class ModalRelacionarDocumentoComponent implements OnInit {

  BS: BucketSalidaMdl = new BucketSalidaMdl();

  //DF: DocumentoFisicoMdl = new DocumentoFisicoMdl();

  doc:DocumentoFisicoMdl = new DocumentoFisicoMdl();
  //docs:DocumentoFisicoMdl[]=[]

  docfi:DocumentoFisicoMdl = new DocumentoFisicoMdl();

  documentos:any = [];
  error:number = 0;
  spinner:number = 0;
  formGroup:FormGroup;
  DFformGroup:FormGroup;

  /*get f() { return this.formGroup.controls; }
  isSave: boolean = true*/

  constructor(
    private _formbuilder:FormBuilder,
    //private form:FormBuilder,
    private BEservice: BucketSalidaService,
    private DFservice:DocumentoFisicoService,
    @Inject(MAT_DIALOG_DATA) public bucketSalida: {documento: number}

  ) {

      this.formGroup = this._formbuilder.group(this.doc);
      this.DFformGroup = this._formbuilder.group(this.docfi);

   }

  ngOnInit(): void {

  }

  buscarDocumento = () => {
    let input = document.querySelector<HTMLInputElement>("#inputBuscar");
    //let folio = input.value.replace(/ /g, "");
    //let folio = input.value.replace(/\s+/g, "");//folio sin espacios
    let folio = input.value.trim();//folio sin espacios al principio y final
    if(folio != ""){
      let numero_documento = { numero_documento: folio };    
      this.BEservice.BuscarDocumentoEntrada(numero_documento).subscribe(
        data =>{
          //console.log('success', data)
          if(data == ""){
            this.error = 1;
            console.log("no se encontro el documento");
          }else{
            this.error = 0;
          }
        
          this.documentos.lenght = 0;
          this.documentos = data;

        },
        error => console.log('oops', error)
      )

    }

  }

  buscarDocumentoRelacionado = (documento:number,event:any) => {
    this.spinner = 1;
    let id_documento = { id_documento: documento };    
    this.BEservice.BuscarDocumentoRelacionadoId(id_documento).subscribe(
      data => {
        if (Object.keys(data).length == 0) {
          this.relacionarDocumento(documento)
          this.spinner = 0;
        }else{
          this.spinner = 0;
          Swal.fire(
            'Error!',
            'El documento de entrada ya esta relacionado!',
            'error'
          )
        }
      },error => {
        console.log(error);
      }

    );
  }

  relacionarDocumento = (documento:number) =>{
    debugger;
    let salida:number = this.bucketSalida.documento;
    /**Relacionar el documento de entrada con el documento de salida*/
    this.BEservice.BucketSalidaFind(salida).subscribe((resp:BucketSalidaMdl) => {//bucket salida
      this.BS = resp;
      this.BS.documentoFisicoId.documentoFisicoRelacionadoId = Number(documento);
      this.formGroup = this._formbuilder.group(this.BS.documentoFisicoId);
      
      let peticion:Observable<any>;
      this.doc = this.formGroup.value;
      this.doc.ctrlActivo = true;
      this.doc.ctrlCreadoPor =1;
      this.doc.ctrlActualizado = new Date();
      this.doc.ctrlActualizadoPor = 1;

      if(this.BS.documentoFisicoId.documentoFisicoId){
        this.BS.documentoFisicoId.ctrlCreado = this.BS.documentoFisicoId.ctrlCreado;
        peticion = this.DFservice.DocumentoEdit(this.BS.documentoFisicoId.documentoFisicoId,this.doc)
      }
      peticion.subscribe(resp => {
      },
      error => {
        if(error){
          console.log(error);
          console.log("Ocurrio un error no se relaciono el documento");
        }
      }

      )

      /**Actualizar el status del documento*/
      this.DFservice.DocumentoFind(Number(documento)).subscribe((resp:DocumentoFisicoMdl) => {
        this.docfi = resp;
        this.docfi.estatusDocumentoId = Number(3);
        this.DFformGroup = this._formbuilder.group(this.docfi);
        let peticionDF:Observable<any>;
        this.docfi = this.DFformGroup.value;
        this.docfi.ctrlActivo = true;
        this.docfi.ctrlCreadoPor =1;
        this.docfi.ctrlActualizado = new Date();
        this.docfi.ctrlActualizadoPor = 1;
        this.docfi.ctrlCreado = this.docfi.ctrlCreado

        peticionDF = this.DFservice.DocumentoEdit(Number(documento),this.docfi)
        peticionDF.subscribe(resp => {
          Swal.fire({
            title: 'Exito',
            text: "El documento se relacionó con éxito",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          })
        },
        error => {
          if(error){
            console.log(error);
            alert("El documento de entrada no se actualizo su estatus a 3");
          }
        }

        )

      });

      /****** */
    }, error =>{
      console.log(error);
      alert("El registro de bucket salida no se obtuvo");
    });

  }

}
