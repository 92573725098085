import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {DocumentoFisicoService} from 'src/app/_utils/services/Service-Entidades/documentoFisico.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-documento-listado-cerrado',
  templateUrl: './documento-listado-cerrado.component.html',
  styleUrls: ['./documento-listado-cerrado.component.scss']
})
export class DocumentoListadoCerradoComponent implements OnInit {
  @ViewChild('buscarPalabra') inputSearch:ElementRef;

  dataDoc: any[] = [];
  Ancho = "90%";
  clase:any = [];
  loading = false;
  loadingdoc =false;

  colsDoc = [
    {field: "asunto", header: "Asunto"},
    {field: "cadenaFolio", header:"Folio Interno"},
    { field: "remitenteDestinatario", header: "Remitente/Destinatario " },
    { field: "estatusDocumento", header: "Estatus Documento" },
    { field: "prioridad", header: "Prioridad" },
    { field: "observacionesCierre", header: "Observaciones" },
  ];
  constructor(
    private documentoService: DocumentoFisicoService,
  ) { }

  ngOnInit(): void {
  }

  busquedaDocumentoCerrado(valor:any){
    if (valor == "") {
      Swal.fire(
        'Error!',
        'No se ha ingresado ninguna palabra!',
        'error'
      );
      this.dataDoc = [{}];
      return false;
    }
    this.loading=true;
    this.loadingdoc=true;
    this.dataDoc = [{}];
    let documento_cerrado = {documento_cerrado:valor[0]};

    this.documentoService.DocumentoCerrado(documento_cerrado).subscribe(
      data => {
        if (data.length == 0 ) {
          Swal.fire(
            'Error!',
            'No se encontró ninguna coincidencia!',
            'error'
          );
          this.inputSearch.nativeElement.value = "";
          this.dataDoc = [{}];
          this.loadingdoc=false;
          return false;
        }
        let datos = data;
        datos.forEach(element => {
        try {
          element.remitenteDestinatario = element.remitenteDestinatarioId.puesto+ ": "+ element.remitenteDestinatarioId.nombre + " " + 
                                     element.remitenteDestinatarioId.apellidoPaterno + " " +
                                     element.remitenteDestinatarioId.apellidoMaterno
        } catch (error) {
          element.remitenteDestinatario = "";
        }
        /*****************/
        try {
          element.estatusDocumento = element.estatusDocumentoId.nombre;
        } catch (error) {
          element.estatusDocumento = " ";
        }
        /*****************/
        try {
          element.prioridad = element.prioridadId.nombre;
        } catch (error) {
          element.prioridad = " ";
        }
        /*****************/
        });
        this.dataDoc = data;
        this.loadingdoc=false;
      },
      error => {
        this.loadingdoc=false;
      }
    );
    
  }

}
