import {Injectable, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class TipoContactoService{
   constructor(private http: HttpClient,){}

   TipoContactoFindall() {
    return this.http.get<any[]>(environment.apiURL + '/webresources/tipocontacto');
}


GetTC() {
    return this.http.get<any[]>(environment.apiURL + '/webresources/tipocontacto');
 }

 GetTratamiento() {
    return this.http.get<any[]>(environment.apiURL + '/webresources/tratamiento');
 }

 TipoContactoCreate(entidad: any) {
    return this.http.post(environment.apiURL + '/webresources/tipocontacto', entidad);
}

TipoContactoFind(id: number) {
    return this.http.get<any>(environment.apiURL + '/webresources/tipocontacto/' + id);
}

TipoContactoEdit(id: number, entidad: any) {
    return this.http.put(environment.apiURL + '/webresources/tipocontacto/' + id, entidad);
}

TipoContactoRemove(id: number) {
    return this.http.delete(environment.apiURL + '/webresources/tipocontacto/' + id);
}


}