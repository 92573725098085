import { Component, OnInit } from '@angular/core';
import { TrazaInternaService } from 'src/app/_utils/services/Service-Entidades/TrazaInternaService';
import { TrazaInternaMdl } from 'src/app/_modelos/Traza_interna';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import { TurnosEnviadosVisorComponent } from '../turnosEnviados-visor/turnosEnviados-visor.component';
import { VisorAdjuntosComponent } from '../visor-adjuntos/visor-adjuntos.component';
import { DinamicoService } from 'src/app/_utils/services/dinamico.service';
import { yearsData } from 'src/app/_utils/interface/interfaz';

@Component({
  selector: 'app-turnos-list-enviados',
  templateUrl: './turnos-list-enviados.component.html',
  styleUrls: ['./turnos-list-enviados.component.scss']
})
export class TurnosListEnviadosComponent implements OnInit {
  turno: TrazaInternaMdl = new TrazaInternaMdl();
  
  data: any[] = []
  turnos: TrazaInternaMdl[] = []
  p: number = 1
  Ancho = "100%";
  mensaje: boolean;
  instruccion: boolean;
  mens = "";
  ins = "";
  loading =false;
  seguimientoTurnos: any[] = [];

  cols: any[] = [
    //{ field: "trazaInternaId", header: "Turno" },
   // { field: "Visualizar", header: "Visualizar", with:"10%"},
    { field: "Acciones", header: "Acciones", width: "10%" },
    { field: "estatus", header: "Estatus del Turno" },
    { field: "OrigenNombre", header: "Área que Turna" },
    { field: "DestinoNombre", header: "Área Destino" },
    { field: "numeroDocumentoOriginal", header: "No. oficio original" },
    { field: "documentoAsunto", header: "Asunto Oficio" },
    { field: "cadenaFolio", header: "Folio Interno" },
    { field: "fechaTurno", header: "Fecha del Turno" },
    { field: "agrupador", header: "Volante de Referencia" },
  ];
  colsSeguimiento: any[] = [
    { field: "estatus", header: "Estatus del Turno" },
    { field: "OrigenNombre", header: "Área que Turna" },
    { field: "DestinoNombre", header: "Área Destino" },
    { field: "numeroDocumentoOriginal", header: "No. oficio original" },
    { field: "documentoAsunto", header: "Asunto Oficio" },
    { field: "cadenaFolio", header: "Folio Interno" },
    { field: "fechaTurno", header: "Fecha del Turno" },
    { field: "agrupador", header: "Volante de Referencia" },
  ];

  isSave: boolean = true
  
  /* array list that containt the years*/
  selectedYear:any;
  YearList:yearsData[] = [];
  /*YearList = [
    {label:"2022", value:"2022"},
    {label:"2021", value:"2021"}
  ];*/

  constructor(
    private TIservice: TrazaInternaService,
    private router: Router,
    public dialog: MatDialog,
    private dinamicoService: DinamicoService,
  ) { }


  ngOnInit() {
    /**the paint the list when load*/
    this.YearList = this.dinamicoService.years();
    let year = this.YearList.map( (item) => item.value);//recorre the array list year
    let actualyear = {year:year[0]}//json that have the first array list
    this.find( 50, 1, actualyear)//The call function for paint list data
  }

  find( registros:number , pagina:number, actualyear:any){
    this.loading =true;
    let numeroFinal= (registros* pagina) 
    let numeroInicio = numeroFinal - registros

    this.TIservice.TrazaInternaFindallenviadosYear(actualyear).subscribe(
      data => {
        console.log(data);
        this.turnos = data;
        this.data = this.turnos;
        this.Ancho = (this.cols.length) * 15 + "%";
        this.data.forEach(element => {
        try {
          //Valida si organizacionorigen es nulo, toma el campo grupo organizacional origen
          if (element.organizacionIdOrigen == null){
              element.OrigenNombre = element.grupoOrganizacionalIdOrigen.nombre
          } else { // Toma el  organizacionOrigen
              element.OrigenNombre = element.organizacionIdOrigen.nombre
          }
        } catch{
          element.OrigenNombre = "";
        }
        try {
          element.cadenaFolio = element.documentoFisicoId.cadenaFolio
        } catch{
          element.cadenaFolio = "";
        }
        try {
          element.numeroDocumentoOriginal = element.documentoFisicoId.numeroDocumentoOriginal
        } catch{
          element.numeroDocumentoOriginal = "";
        }
        try {
          element.documentoAsunto = element.documentoFisicoId.asunto
        } catch{
          element.documentoAsunto = "";
        }
        try {
          element.fechaTurno =new Date(element.ctrlCreado).toLocaleDateString();
        } catch{
          element.fechaTurno = "";
        }
        try {//Valida si organizacionorigen es nulo, toma el campo grupo organizacional origen
          if (element.organizacionIdDestino == null){
            element.DestinoNombre = element.grupoOrganizacionalIdDestino.nombre
          } else { // Toma el  organizacionOrigen
            element.DestinoNombre = element.organizacionIdDestino.nombre
          }
        } catch{
          element.DestinoNombre = "";
        }
        try {
          element.estatus = element.estatusTurnoId.nombre
        } catch{
          element.estatus = "";
        }
        try {
          element.agrupador = element.agrupador + "/" + "2021";
        } catch{
          element.agrupador = "";
        }
        });

        this.loading =false;
      },
      error => {
        console.log(error);
      }
    );

  }

  borrarTurno(tur: TrazaInternaMdl, i: number) {
    Swal.fire({
      title: 'Eliminar',
      text: `Eliminará  ${tur.trazaInternaId}`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then(resp => {
      if (resp.value) {
        // this.dependencias.splice(i, 1)
        this.turnos = this.turnos.filter(a => a.trazaInternaId !== tur.trazaInternaId)
        this.data = this.turnos.filter(a => a.trazaInternaId !== tur.trazaInternaId)
        tur.ctrlActivo = false;
        console.log(tur)
        this.TIservice.TrazaInternaRemove(
          tur.trazaInternaId
        ).subscribe()
      }
    })
  }


  visorDocumentos(data) {

    if (data.documentoId != null) {
      this.router.navigate(['/turnos/visorEnviados/documento/', data.documentoId.documentoId]);
    }
    else {
      //this.router.navigate(['/turnos/visorEnviados/documentoFisico/', data.documentoFisicoId.documentoFisicoId]);
      const dialogRef = this.dialog.open(TurnosEnviadosVisorComponent,{
        width: '50%',
        height: '90%',
        data: { id: data.documentoFisicoId.documentoFisicoId }, 
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }

  }

  visorAdjuntos(data) {

    let tipoDoc: String = "";

    if (data.documentoId == null) {
      tipoDoc="documentoFisico";
    }
    else {
      tipoDoc="documento";
    }
    const dialogRef = this.dialog.open(VisorAdjuntosComponent, {
      width: '50%',
      height: '90%',
      data: { id: data.documentoFisicoId.documentoFisicoId, tipoDoc: tipoDoc },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }

  DocRelacionado(data) {
    if (data.documentoId == null) {
      this.router.navigate(['/documentorelacionado/formulariofisico/' + data.documentoFisicoId.documentoFisicoId + "/new/bucketEntrada"]);
    }
    else {
      this.router.navigate(['/documentorelacionado/formulario/', data.documentoId.documentoId + "/new/bucketEntrada"]);
    }

  }


  Mensaje(turnos) {
    this.mensaje = true;
    this.TIservice.TrazaInternaFind(Number(turnos.trazaInternaId)).subscribe((resp: TrazaInternaMdl) => {
    this.mens = resp.mensaje;

    })
  }


  Instrucciones(turnos) {
    this.instruccion = true;
    this.TIservice.TrazaInternaFind(Number(turnos.trazaInternaId)).subscribe((resp: TrazaInternaMdl) => {
    this.ins = resp.instrucciones;

    })
  }

  CerrarTurno(turno):void {
    const id = turno.trazaInternaId;
    if (id !== 'new') {
      this.isSave = false
      this.TIservice.TrazaInternaFind(Number(id)).subscribe((resp: TrazaInternaMdl) => {
        resp.estatusTurnoId = 3;
        this.ngOnInit();
        let peticion: Observable<any>
        Swal.fire({
          title: 'Espere',
          text: 'Guardando información',
          icon: 'info',
          allowOutsideClick: false
        })
        peticion = this.TIservice.AutorizarEstatus(resp.trazaInternaId,resp)
        peticion.subscribe(resp => {
          this.ngOnInit();
          Swal.fire({
            title: id,
            text: 'Realizado correctamente',
            icon: 'success',
          })
        },

          error => {
            if (error) {

            }

            Swal.fire({
              title:id,
              text: 'error',
              icon: 'error',
            })
          },

        )




      })

    }

  }

  /**Change the list year*/
  changeYear = (event) => {
    //console.log(event.value);
    let yearChange = {year:event.value};
    this.data.length = 0;//the reset the array
    this.turnos.length = 0//the reset the array
    this.find( 50, 1, yearChange);//the call function for paint the data when change select
    this.selectedYear = event.value; //selected item of value
  }

  rowExpanded = (rowData:any) => {
    console.log(rowData);
    rowData.expanded = !rowData.expanded;    
    if (rowData.expanded) {
      const documentoFisicoId = rowData.documentoFisicoId.documentoFisicoId;
      const trazainterna = rowData.trazaInternaId
      //const organizacionOrigenId = rowData.organizacionIdOrigen.organizacionId;
      const data = {
        documentofisicoid: documentoFisicoId,
        trazaInternaId: trazainterna
        //organizacionorigenid :organizacionOrigenId,
      }    
      console.log(data);
      this.TIservice.TrazaInternaFindAllSeguimientoEnviado(data).subscribe({
        next: (seg) =>{
          console.log(seg);
          this.seguimientoTurnos = seg;
          this.seguimientoTurnos.forEach(element => {
            try {
              //Valida si organizacionorigen es nulo, toma el campo grupo organizacional origen
              if (element.organizacionIdOrigen == null){
                  element.OrigenNombre = element.grupoOrganizacionalIdOrigen.nombre
              } else { // Toma el  organizacionOrigen
                  element.OrigenNombre = element.organizacionIdOrigen.nombre
              }
            } catch{
              element.OrigenNombre = "";
            }
            try {
              element.cadenaFolio = element.documentoFisicoId.cadenaFolio
            } catch{
              element.cadenaFolio = "";
            }
            try {
              element.numeroDocumentoOriginal = element.documentoFisicoId.numeroDocumentoOriginal
            } catch{
              element.numeroDocumentoOriginal = "";
            }
            try {
              element.documentoAsunto = element.documentoFisicoId.asunto
            } catch{
              element.documentoAsunto = "";
            }
            try {
              element.fechaTurno =new Date(element.ctrlCreado).toLocaleDateString();
            } catch{
              element.fechaTurno = "";
            }
            try {//Valida si organizacionorigen es nulo, toma el campo grupo organizacional origen
              if (element.organizacionIdDestino == null){
                element.DestinoNombre = element.grupoOrganizacionalIdDestino.nombre
              } else { // Toma el  organizacionOrigen
                element.DestinoNombre = element.organizacionIdDestino.nombre
              }
            } catch{
              element.DestinoNombre = "";
            }
            try {
              element.estatus = element.estatusTurnoId.nombre
            } catch{
              element.estatus = "";
            }
            try {
              element.agrupador = element.agrupador + "/" + "2021";
            } catch{
              element.agrupador = "";
            }
          });
          console.log(this.seguimientoTurnos);
        },
        error:(err) => { console.log(err);},
      });
    }
  }

}




