<section class="content-header header-siadci">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <h1>Listado Indicadores por Documento</h1>
            </div>
            <div class="col-sm-6">
                <h3>Indicador Documento</h3>
            </div>
            <div class="col-sm-6 text-right">
                <a class="btn-siadci-without-line" [routerLink]="['/documentofisico/lista']">
                    <i class="fas fa-th-large"></i>
                    Ir a Listado Documento Físico
                </a>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content content-sidci">
    <div class="container-fluid">
        <div class="siadci-content-wrapper pt-3">
            <div style="
            width: 95%;
            overflow: auto;
            margin-left: auto;
            margin-right: auto;
            ">
                <div *ngIf="isLoading==true"
                    style="width: 0vw; height: 50vh; margin-left: auto; margin-right: auto;  margin-top: auto; margin-bottom: auto;">
                    <div style=" height: 30vh;  margin-top: auto; margin-bottom: auto;">

                    </div>
                    <mat-spinner></mat-spinner>
                </div>

                <div *ngIf="isLoading==false" style="width: 100%;">
                    <div class="row" *ngIf="documentoFisico">
                        <div class="col">Documento</div>
                        <div class="col">Cadena folio:{{documentoFisico.cadenaFolio}}</div>
                        <div class="col">Documento Original:{{documentoFisico.numeroDocumentoOriginal}}</div>
                    </div>
                    <div style="height: 30px;">
                    </div>
                    <div>
                        <h3>Lista indicadores</h3>
                    </div>
                    <div style="height: 0px;">
                    </div>
                    <!--    <div class="col-9">Fecha: {{documentoIndicador.ctrlCreado |  date:'d/M/yy, h:mm a' }}</div>-->
                    <!--ng-container *ngFor="let documentoIndicador of IndicadoresDocumentoNuevos">
                        <div style="height: 20px"></div>
                        <div class="row">
                            <div class="col-12">
                                <h3 class="first">Indicador: {{ documentoIndicador.indicadorId.nombre }}</h3>
                                <p>{{ documentoIndicador.indicadorId.indicadorId }}</p>
                                <div class="row">
                                    <div class="col-3">
                                        <ng-container>
                                            <p-selectButton [options]="options"
                                                [(ngModel)]="documentoIndicador.indicador" required>
                                            </p-selectButton>
                                        </ng-container>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </ng-container>-->
                    <ng-container >
                        <div >
                            <div style="height: 20px"></div>
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="first">Requerimientos de software para la Subdirección de Gobierno Electrónico</h3>
                                    <div class="row">
                                        <div class="col-4" >
                                            <div *ngFor="let f1documentoIndicador of IndicadoresDocumentoNuevos">
                                                <div *ngIf="f1documentoIndicador.indicadorId.indicadorId == 8">
                                                    <!--p>{{ f1documentoIndicador.indicadorId.indicadorId }}</p>-->
                                                    <div class="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">
                                                        <div class="btn-group mr-2" role="group" aria-label="First group">
                                                            <p-selectButton [options]="options"
                                                            [(ngModel)]="f1documentoIndicador.indicador"   required>
                                                            </p-selectButton>
                                                        </div>
                                                        <div class="input-group">
                                                            <p class="mt-2"><b>Solicitud</b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-8" >
                                            <div *ngFor="let f2documentoIndicador of IndicadoresDocumentoNuevos">
                                                <div *ngIf="f2documentoIndicador.indicadorId.indicadorId == 5">
                                                <!--p>{{ f2documentoIndicador.indicadorId.indicadorId }}</p>-->
                                                <div class="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">
                                                    <div class="btn-group mr-2" role="group" aria-label="First group">
                                                        <p-selectButton [options]="options"
                                                         [(ngModel)]="f2documentoIndicador.indicador" required>
                                                        </p-selectButton>
                                                    </div>
                                                    <div class="input-group">
                                                        <p class="mt-2"><b>Atención</b></p>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>    
                    </ng-container>
                    <ng-container >
                        <div >
                            <div style="height: 20px"></div>
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="first">Asesorías técnicas a usuarios de aplicativos de software</h3>
                                    <div class="row">
                                        <div class="col-4" >
                                            <div *ngFor="let f3documentoIndicador of IndicadoresDocumentoNuevos">
                                                <div *ngIf="f3documentoIndicador.indicadorId.indicadorId == 7">
                                                    <!--p>{{ f3documentoIndicador.indicadorId.indicadorId }}</p>-->
                                                    <div class="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">
                                                        <div class="btn-group mr-2" role="group" aria-label="First group">
                                                            <p-selectButton [options]="options"
                                                            [(ngModel)]="f3documentoIndicador.indicador"   required>
                                                            </p-selectButton>
                                                        </div>
                                                        <div class="input-group">
                                                            <p class="mt-2"><b>Solicitud</b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-8" >
                                            <div *ngFor="let f4documentoIndicador of IndicadoresDocumentoNuevos">
                                                <div *ngIf="f4documentoIndicador.indicadorId.indicadorId == 4">
                                                <!--p>{{ f4documentoIndicador.indicadorId.indicadorId }}</p>-->
                                                <div class="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">
                                                    <div class="btn-group mr-2" role="group" aria-label="First group">
                                                        <p-selectButton [options]="options"
                                                         [(ngModel)]="f4documentoIndicador.indicador" required>
                                                        </p-selectButton>
                                                    </div>
                                                    <div class="input-group">
                                                        <p class="mt-2"><b>Atención</b></p>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>    
                    </ng-container>
                    <ng-container >
                        <div >
                            <div style="height: 20px"></div>
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="first">Proyectos de software desarrollados</h3>
                                    <div class="row">
                                        <div class="col-4" >
                                            <div *ngFor="let f5documentoIndicador of IndicadoresDocumentoNuevos">
                                                <div *ngIf="f5documentoIndicador.indicadorId.indicadorId == 6">
                                                    <!--p>{{ f5documentoIndicador.indicadorId.indicadorId }}</p>-->
                                                    <div class="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">
                                                        <div class="btn-group mr-2" role="group" aria-label="First group">
                                                            <p-selectButton [options]="options"
                                                            [(ngModel)]="f5documentoIndicador.indicador"   required>
                                                            </p-selectButton>
                                                        </div>
                                                        <div class="input-group">
                                                            <p class="mt-2"><b>Solicitud</b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-8" >
                                            <div *ngFor="let f6documentoIndicador of IndicadoresDocumentoNuevos">
                                                <div *ngIf="f6documentoIndicador.indicadorId.indicadorId == 1">
                                                <!--p>{{ f6documentoIndicador.indicadorId.indicadorId }}</p>-->
                                                <div class="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">
                                                    <div class="btn-group mr-2" role="group" aria-label="First group">
                                                        <p-selectButton [options]="options"
                                                         [(ngModel)]="f6documentoIndicador.indicador" required>
                                                        </p-selectButton>
                                                    </div>
                                                    <div class="input-group">
                                                        <p class="mt-2"><b>Atención</b></p>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>    
                    </ng-container>
                    <div style="height: 30px;"></div>
                    <button type="submit" class="btn-primary-siadci" (click)="guardarIndicadores()">
                        <i class="fa fa-edit"></i> Guardar cambios
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>