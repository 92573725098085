<section class="content-header header-siadci">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <h1>Listado Turnos Recibidos</h1>
      </div>
      <div class="col-sm-6">
        <h3>Muestra los registros de Turnos Recibidos (Totales)</h3>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<section class="content content-sidci">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card-siadci py-4">
      <div style="
          width: 95%;
          overflow: auto;
          margin-left: auto;
          margin-right: auto;
        ">
        <div class="table-siadci">

          <app-paginador #paginador  (change)="find( paginador.registros, paginador.page, null)"></app-paginador>

          <mat-spinner *ngIf="this.loading"></mat-spinner>

          <p-table #dt class="ng2-smart-table"
            [resizableColumns]="true" styleClass="p-datatable-gridlines" [autoLayout]="true" 
            [columns]="cols" [value]="data" paginator="true" [rows]="5" *ngIf="!this.loading" [style]="{width:'1500px'}">
            <ng-template pTemplate="caption">
              <!-- <div style="text-align: left">
                  <i class="fa fa-search" style="margin: 4px 4px 0 0"></i>
                  <input
                    type="text"
                    pInputText
                    size="100"
                    placeholder="Filtro global"
                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                    style="width: auto"
                  />
                </div> -->
              <div class="filtros">
                <div class="filtro1">
                  <div>
                    <p-dropdown [options]="YearList" [(ngModel)]="selectedYear" (onChange)="changeYear($event)" id="select-data-anio">
                    </p-dropdown>
                  </div>
                </div>
                <div class="filtro2">
                  <div class="search-wrapper-siadci d-flex">
                    <input type="text" pInputText placeholder="Filtro global" class="search-input"
                      (input)="dt.filterGlobal($event.target.value, 'contains')" />
                    <i class="fa fa-search"></i>
                  </div>
                </div>
              </div>
              
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [ngStyle]="{'width': col.width,'text-align': 'center' }">
               <!--   <ng-container *ngIf="col.field == 'Visualizar'">
                      <i class="pi pi-tag"></i>
                  </ng-container>-->
                  {{ col.header }}
                </th>
              </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field">
                  <p-sortIcon [field]="col.field" [pSortableColumn]="col.field"></p-sortIcon>

                  <input *ngSwitchCase="'fechaTurno'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                      dt.filter($event.target.value, col.field, 'contains')
                    " />

                  <input *ngSwitchCase="'cadenaFolio'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                      dt.filter($event.target.value, col.field, 'contains')
                    " />

                  <input *ngSwitchCase="'numeroDocumentoOriginal'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                      dt.filter($event.target.value, col.field, 'contains')
                    " />

                  <input *ngSwitchCase="'OrigenNombre'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                      dt.filter($event.target.value, col.field, 'contains')
                    " />

                  <input *ngSwitchCase="'DestinoNombre'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                      dt.filter($event.target.value, col.field, 'contains')
                    " />

                  <input *ngSwitchCase="'agrupador'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                      dt.filter($event.target.value, col.field, 'contains')
                    " />

                  <input *ngSwitchCase="'documentoAsunto'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                      dt.filter($event.target.value, col.field, 'contains')
                    " />

                  <input *ngSwitchCase="'estatus'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                      dt.filter($event.target.value, col.field, 'contains')
                    " />

                    <input *ngSwitchCase="'prioridad'" pInputText type="text" placeholder="Contiene"
                    class="ui-column-filter" style="width: 100%" (input)="
                      dt.filter($event.target.value, col.field, 'contains')
                    " />
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
              <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns">
                  <div style="display: flex; white-space: normal; overflow: hidden">
                  <!--  <ng-container *ngIf="col.field == 'Visualizar'">
                        <div style="text-align: center;">
                          <div>
                            <button type="button" (click)="rowExpanded(rowData)" class="btn" >
                              <i class="pi" [ngClass]="rowData.expanded ? 'pi-chevron-down' : 'pi-chevron-right'"></i>
                            </button>
                          </div>
                        </div>
                    </ng-container>-->
                    <ng-container *ngIf="col.field == 'Acciones'">
                      <div style="text-align: center;">
                        <button pButton type="button" label="Visor" class="btn-in-table main-color"
                          (click)="visorDocumentos(rowData)"></button>

                        <button pButton type="button" label="Adjuntos" class="btn-in-table main-color"
                          (click)="visorAdjuntos(rowData)"></button>

                    <!--    <button pButton type="button" label="Acuses" class="btn-in-table main-color"
                          (click)="visorAcuses(rowData)"></button> -->
                    <ng-container *ngIf="apiService.BuscarPermiso('/webresources/trazainterna','post')"> 
                       <button *ngIf="rowData.estatus == 'Recibido' || rowData.estatus == 'Returnado'  || rowData.estatus == 'Atención Personal'"
                            pButton type="button" [routerLink]="[
                            '/turnos/formulario/' + rowData.trazaInternaId
                          ]" label="Responder turno" class="btn-in-table outline-color"></button>
                     </ng-container>
                      
                        <button type="button" *ngIf="
                            rowData.estatus == 'Respuesta' ||
                            rowData.mensaje != ''
                          " (click)="Mensaje(rowData)" pButton icon="pi pi-external-link" label="Mensaje"
                          class="btn-in-table outline-color"></button>
                        <p-dialog header="Mensaje" [(visible)]="mensaje" [modal]="true" [style]="{ width: '50vw' }"
                          [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
                          {{ mens }}

                          <!--<p-footer>
                          <button type="button"  (click)="Instrucciones(rowData)" pButton icon="pi pi-external-link"
                           label="Instrucciones"></button>
                          <p-dialog header="Instrucciones" [(visible)]="instruccion" [modal]="true" [style]="{width: '50vw'}"
                          [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
                          {{ins}}

                        </p-dialog>
                        </p-footer>-->
                        </p-dialog>

                        <button pButton type="button" (click)="Instrucciones(rowData)" pButton
                          class="btn-in-table outline-color" icon="pi pi-external-link" label="Instrucciones"></button>
                        <p-dialog header="Instrucciones" [(visible)]="instruccion" [modal]="true"
                          [style]="{ width: '50vw' }" [maximizable]="true" [baseZIndex]="10000" [draggable]="false"
                          [resizable]="false">
                          {{ ins }}
                        </p-dialog>

                        <!--Si el estatus es Atendido, mostrar el botón-->
                       <!-- <button *ngIf="rowData.estatus == 'Respuesta' || rowData.estatus == 'Atención Personal'" pButton type="button"
                        -->
                        <ng-container *ngIf="apiService.BuscarPermiso('/webresources/documentofisico','post')">
                          <button *ngIf="rowData.estatus != 'Cerrado'" pButton type="button"
                          (click)="DocRelacionado(rowData)" class="btn-in-table outline-color"
                            label="Responder oficio"></button>
                        </ng-container>

                        <ng-container *ngIf=" rowData.organizacionIdDestino.organizacionId == 3 ||
                                             rowData.organizacionIdDestino.organizacionId == 244 ||
                                             rowData.organizacionIdDestino.organizacionId == 247  ||
                                             rowData.organizacionIdDestino.organizacionId == 250"> 
                            <button pButton type="button" class="btn-in-table outline-color" label="Indicador" 
                            routerLink="/documentofisico/indicador/{{rowData.documentoFisicoId.documentoFisicoId}}"></button>
                        </ng-container>

                    <ng-container *ngIf="apiService.BuscarPermiso('/webresources/trazainterna','post')"> 
                        <button *ngIf="rowData.estatus != 'Cerrado'" pButton type="button" [routerLink]="[
                            '/turnos/formularios/' + rowData.trazaInternaId
                          ]" label="Turnar" class="btn-in-table main-color"></button>
                     </ng-container> 
                    
                          <button *ngIf="rowData.estatus == 'Recibido' || rowData.estatus == 'Returnado'  "  pButton type="button" label="Asignación Personal"  
                          icon="pi pi-external-link" class="btn-in-table outline-color" 
                          (click)="AsignacionPersonal(rowData)"></button>
                        
                        <ng-container *ngIf="apiService.BuscarPermiso('/webresources/trazainterna/{id}','put')"> 
                          <button
                          *ngIf="rowData.estatus != 'Cerrado'"
                          pButton type="button" [routerLink]="['/turnos/ObservacionesTR/'+ rowData.trazaInternaId]"
                          label="Cierre Oficio Conocimiento" class="btn-in-table outline-color"></button>
                       </ng-container> 
                      </div>
                    </ng-container>
                    {{ rowData[col.field] }}
                  </div>
                </td>
              </tr>
              <tr *ngIf="rowData.expanded">
                <td colspan="11" class="m-0">
                  <div>
                    <p-table [value]="seguimientoTurnos" [columns]="colsSeguimiento">
                      <ng-template pTemplate="header" let-columns>
                      <tr>
                          <th *ngFor="let col of columns" [ngStyle]="{'width': col.width,'text-align': 'center' }">
                          {{ col.header }}
                          </th>
                      </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowDataSeguimiento let-columns="columns">
                          <tr [pSelectableRow]="rowDataSeguimiento">
                              <td *ngFor="let col of columns">
                                  {{rowDataSeguimiento[col.field]}}
                              </td>
                          </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <!-- <div class="card-footer"></div> -->
    </div>
  </div>
</section>